import { routes } from "../services/placements";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";

export function usePlacements(layoutId: string, page = 0, limit = 1000) {
  const revalidationEnabled = page === 0;
  const { data, error, mutate } = useSWR(
    () =>
      layoutId
        ? { url: routes.list, queryParam: { layoutId, page, items: limit } }
        : null,
    fetcher<any>,
    {
      revalidateIfStale: revalidationEnabled,
      revalidateOnFocus: revalidationEnabled,
      revalidateOnReconnect: revalidationEnabled,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    placements: data ?? [],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
