import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { routes } from "../services/service-orders.service";
import { ServiceOrderReport } from "../entities";

export function useServiceOrdersReport(
  page = 0,
  limit = 1000,
  filter?:
    | {
        organizationId: string | undefined;
        locationId: string | undefined;
        sortDirection: string | undefined;
        sort: string;
        status?: string;
        from?: Date;
        to?: Date;
      }
    | undefined
) {
  const { data, error, mutate } = useSWR(
    {
      url: routes.reports,
      queryParam: {
        page,
        items: limit,
        ...filter,
      },
    },
    fetcher<any>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    reports: (data?.content ?? []) as ServiceOrderReport.Type[],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
