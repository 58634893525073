import { routes } from "../services/locations";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { Location } from "../entities";

export function useLocation(locationId: string) {
  const { data, error } = useSWR(
    { url: routes.findById(locationId) },
    fetcher<Location.Type>,
    {
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    location: data,
    isLoading: !error && !data,
    isError: error,
  };
}
