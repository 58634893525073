import { useMemo } from "react";

import { withRouter } from "react-router-dom";

import { useUser } from "../../hooks/useUser";
import BuildingManagerHome from "./BuildingManager/Home";
import AdminHome from "./Admin/Home";
import ResidentHome from "./Resident/Home";

const HallnewsHome = ({ match, ...props }: any) => {
  const { user } = useUser();

  const content = useMemo(() => {
    if (user?.role === "BUILDING_MANAGER") {
      return <BuildingManagerHome />;
    } else if (user?.role === "RESIDENT") {
      return <ResidentHome />;
    }
    return <AdminHome />;
  }, [user]);

  return <div>{content}</div>;
};

export default withRouter(HallnewsHome);
