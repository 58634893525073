import React, { forwardRef, useEffect, useRef } from "react";
import { Textarea as UiTextarea } from "./ui/textarea";
import { Label } from "./Label";

interface InputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  required?: boolean;
}

const Textarea = forwardRef<HTMLTextAreaElement, InputProps>(
  ({ label, required, error, ...props }, forwardedRef) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (forwardedRef) {
        if (typeof forwardedRef === "function") {
          forwardedRef(inputRef.current);
        } else if (typeof forwardedRef === "object") {
          forwardedRef.current = inputRef.current;
        }
      }
    }, [forwardedRef]);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.setCustomValidity(error || "");
      }
    }, [error]);

    return (
      <div className="space-y-0">
        {label && (
          <Label label={`${label}${required ? "*" : ""}`} error={!!error} />
        )}
        <UiTextarea ref={inputRef} {...props} />
        {error && <p className="text-xs text-destructive">{error}</p>}
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
