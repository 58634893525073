import { format } from "date-fns";
import { Calendar as CalendarIcon, LucideX } from "lucide-react";
import { fr } from "date-fns/locale";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import { Calendar } from "../ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Label } from "../Label";
import { DateRange } from "react-day-picker";

interface Props {
  label?: string;
  disabled?: boolean;
  value?: DateRange;
  onChange: (date: DateRange | undefined) => void;
  dob?: boolean;
}
export function RangePicker({ label, disabled, value, onChange, dob }: Props) {
  const clear = () => {
    onChange(undefined);
  };
  return (
    <Popover>
      <div className={"flex items-center space-x-0"}>
        <PopoverTrigger asChild>
          <div className="flex flex-col">
            {label && <Label label={label} />}
            <Button
              variant={"outline"}
              disabled={disabled}
              type="button"
              className={cn(
                "w-full justify-start text-left font-normal",
                !value && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {value?.from && value?.to ? (
                `${format(new Date(value.from), "PPP", {
                  locale: fr,
                })} - ${format(new Date(value.to), "PPP", { locale: fr })} `
              ) : (
                <span>Date</span>
              )}
            </Button>
          </div>
        </PopoverTrigger>
        {!!value && (
          <Button variant="ghost" size="icon" className="" onClick={clear}>
            <LucideX size={12} />
          </Button>
        )}
      </div>

      <PopoverContent className="w-auto p-0">
        <Calendar mode="range" value={value} onSelect={onChange} />
      </PopoverContent>
    </Popover>
  );
}
