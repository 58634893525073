import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { Locker } from "../entities";

export interface LockerResponse extends PartialApiItemsResponse {
  content: Locker.Type[];
}

export const fetchLockers = (page: number, items: number) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sortDirection", "ASC");

  return new Promise<LockerResponse>((resolve, reject) => {
    axios
      .get(`/v2/lockers?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const refreshLocker = (id: string): Promise<Locker.Type> => {
  return axios.get(`/v1/lockers/${id}`).then((response) => response.data);
};

export const sendHealthCheck = (id: string) => {
  return axios.post(`/v2/lockers/${id}/health`);
};

export const sendHealthCheckForAllLockers = () => {
  return axios.post("/v2/lockers/monitor");
};

export const submitLocker = (item: Partial<Locker.Type>, isNew?: boolean) => {
  if (isNew) {
    return axios.post("/v2/lockers", { ...item, contract: "test" });
  } else {
    return axios.put(`/v2/lockers/${item.id}`, { ...item, contract: "test" });
  }
};

export const deleteLocker = (id: string) => {
  return axios.delete(`/v1/lockers/${id}`);
};
