import HallnewsComponentContent from "../entities/HallnewsComponentContent";
import { apiFetch } from "../lib/apiFetch";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  list: `${API}/hallnews/contents`,
  findById: (id: number) => `${API}/hallnews/components/${id}`,
};

export const create = (body: HallnewsComponentContent.Type) =>
  apiFetch({
    method: "POST",
    url: `${API}/hallnews/contents`,
    body,
    authRequired: true,
  });

export const update = (body: HallnewsComponentContent.Type) =>
  apiFetch({
    method: "POST",
    url: `${API}/hallnews/contents/${body.id}`,
    body,
    authRequired: true,
  });

export const deleteContent = (id: number) =>
  apiFetch({
    method: "DELETE",
    url: `${API}/hallnews/contents/${id}`,
    authRequired: true,
  });
