import { useMemo } from "react";
import { useSession } from "./SessionProvider";

interface UserAccess {
  hasServiceOrdersAccess: boolean;
  hasNotificationsAccess: boolean;
  hasLockersAccess: boolean;
  hasUsersAccess: boolean;
  hasLocationsAccess: boolean;
  hasReportsAccess: boolean;
  hasOrganisationsAccess: boolean;
}

export function useUserAccess() {
  const { user } = useSession();

  const userAccess = useMemo(() => {
    if (!user) {
      return;
    }

    const hasOrganisationsAccess = user.role === "MARVELOCK_ADMIN";
    const hasNotificationsAccess =
      user.role === "MARVELOCK_ADMIN" ||
      user.role === "SITE_MANAGER" ||
      user.role === "BUILDING_MANAGER" ||
      user.role === "RESIDENT";
    const hasServiceOrdersAccess =
      user.role === "MARVELOCK_ADMIN" ||
      user.role === "SITE_MANAGER" ||
      (user.role === "RESIDENT" && user.lockerFeatureEnabled);
    const hasUsersAccess =
      user.role === "MARVELOCK_ADMIN" ||
      user.role === "SITE_MANAGER" ||
      user.role === "BUILDING_MANAGER";
    const hasLockersAccess = user.role === "MARVELOCK_ADMIN";
    const hasLocationsAccess = user.role === "MARVELOCK_ADMIN";
    const hasReportsAccess = user.role === "MARVELOCK_ADMIN";

    return {
      hasOrganisationsAccess,
      hasNotificationsAccess,
      hasServiceOrdersAccess,
      hasLocationsAccess,
      hasLockersAccess,
      hasUsersAccess,
      hasReportsAccess,
    };
  }, [user]);

  return userAccess;
}
