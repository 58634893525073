import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Nav from "../components/Nav";
import TopBar from "../components/TopBar";

const PageWrapper: React.FC<BoxProps> = ({ children, ...rest }) => {
  return (
    <div className="flex h-full w-screen bg-background">
      <Nav />
      <div className="flex flex-col items-stretch w-full">
        <TopBar />
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
