import { routes } from "../services/components";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import HallnewsComponentContent from "../entities/HallnewsComponentContent";

interface Query {
  page: number;
  items: number;
  sort?: string;
  sortDirection?: string;
}

interface Response {
  content: HallnewsComponentContent.Type[];
  number: number;
  last: boolean;
  totalElements: number;
  totalPages: number;
}

export function useComponentContent(componentId: number, query: Query) {
  const { data, error, mutate } = useSWR(
    {
      url: routes.listContent(componentId),
      queryParam: {
        ...query,
      },
    },
    fetcher<Response>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    content: data?.content ?? [],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
