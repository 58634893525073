import { withRouter } from "react-router-dom";
import { Col, Row } from "../../styles/layout";
import { Text } from "../../styles/typography";

import { useLocationLayout } from "../../hooks/useLayout";
import { useLocation } from "../../hooks/useLocation";
import { useEffect, useState } from "react";
import AttachComponentsDialog from "./__containers/AttachComponentsDialog";
import { usePlacements } from "../../hooks/usePlacements";
import { ClickableCard } from "../../components/ClickableCard";
import { ChatBubbleIcon } from "@radix-ui/react-icons";
import HallnewsComponentPlacement from "../../entities/HallnewsComponentPlacement";
import { useRouter } from "../../hooks/useRouter";
import PlacementsGrid from "./__containers/PlacementsGrid";
import { useTranslation } from "react-i18next";
import LayoutConfig from "./__containers/LayoutConfig";
import { Button } from "../../components/ui/button";

const HallnewsLayout = ({ match }: any) => {
  const { t: getTranslationByLabel } = useTranslation();

  const { locationId } = match.params;
  const { layout, refresh: refreshLayout } = useLocationLayout(locationId);
  const { placements, refresh: refreshPlacements } = usePlacements(layout?.id);
  const { location } = useLocation(locationId);
  const [showWidgetList, setShowWidgetList] = useState<boolean>(false);
  const [showLayoutUpdate, setShowLayoutUpdate] = useState<boolean>(false);

  useEffect(() => {
    if (placements.length) {
      for (let placement of placements) {
        console.log(
          `col-start ${placement.gridCol + 1} / col-start ${
            placement.gridCol + 1 + placement.gridColSpan
          }`
        );
      }
    }
  }, [placements]);

  return (
    <>
      <div className="space-y-6">
        <div>
          <div className="text-muted-foreground text-sm font-semibold">
            {getTranslationByLabel("hallnews-layout")}
          </div>
          <div className="flex  justify-between items-end">
            <div className="text-xl font-semibold">
              {`${location?.companyName} ${location?.name}`}
            </div>
            <Button onClick={() => setShowLayoutUpdate(true)}>
              {getTranslationByLabel("edit")}
            </Button>
          </div>
        </div>
        <div className="">
          {location && (
            <div className="">
              <PlacementsGrid
                location={location}
                layout={layout}
                placements={placements}
                refreshPlacements={refreshPlacements}
                onAdd={() => setShowWidgetList(true)}
              />
            </div>
          )}
        </div>
      </div>
      {layout && (
        <>
          <LayoutConfig
            isOpen={showLayoutUpdate}
            onClose={() => setShowLayoutUpdate(false)}
            layout={layout}
            onUpdate={refreshLayout}
          />
          <AttachComponentsDialog
            open={showWidgetList}
            onClose={() => {
              setShowWidgetList(false);
              refreshPlacements();
            }}
            layout={layout}
            placements={placements}
          />
        </>
      )}
    </>
  );
};

export default withRouter(HallnewsLayout);

const PlacementCard = ({
  placement,
  onSelect,
}: {
  placement: HallnewsComponentPlacement.Type;
  onSelect: () => void;
}) => {
  return (
    <ClickableCard onClick={onSelect}>
      <Row
        borderRadius="1"
        bg="bgBase"
        boxShadow="0 2px 3px 0px rgba(0,0,0,0.1)"
        p="3"
        alignItems={"center"}
        mb="5"
        position="relative"
        backgroundColor="bgBase"
      >
        <Col
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          bg="element"
          width={56}
          height={56}
        >
          <ChatBubbleIcon width={32} height={32} />
        </Col>
        <Col p="3">
          <Text color="textHigh" fontSize="18px">
            {placement.component!.label}
          </Text>
          <Text color="textLow" fontWeight="300" fontSize="16px">
            {placement.component!.content?.length
              ? `${placement.component!.content.length} messages`
              : "Cet élément est vide"}
          </Text>
        </Col>
      </Row>
    </ClickableCard>
  );
};
