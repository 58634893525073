import { apiFetch } from "../lib/apiFetch";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  login: `${API}/auth/login`,
  token: `${API}/auth/token`,
  signOut: (agent: string) => `${API}/auth/devices/${agent}`,
};

interface LoginRequest {
  email: string;
  otp?: string;
}

export interface LoginResponse {
  expiresAt: string;
  accessToken: string;
  refreshToken: string;
  refreshExpiresAt: string;
}
export const login = (input: LoginRequest) =>
  apiFetch<LoginResponse>({
    method: "POST",
    url: routes.login,
    body: input,
    authRequired: false,
    credentials: true,
  });

export const token = () =>
  apiFetch<LoginResponse>({
    method: "POST",
    url: routes.token,
    authRequired: true,
    jwtType: "refresh",
    credentials: true,
  });
