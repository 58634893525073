import { Button, ButtonProps } from "../components/ui/button";
import toast from "react-hot-toast";
import { LucideCopy } from "lucide-react";

interface OwnProps extends Omit<ButtonProps, "onClick"> {
  valueToCopy: string;
  label?: string;
}

const CopyToClipboardButton = ({ label, valueToCopy, ...props }: OwnProps) => {
  return (
    <Button
      variant="ghost"
      size="icon"
      {...props}
      onClick={() => {
        navigator.clipboard.writeText(valueToCopy);
        toast.success("Id copied");
      }}
    >
      <LucideCopy size={16} />
    </Button>
  );
};

export default CopyToClipboardButton;
