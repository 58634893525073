import { useTranslation } from "react-i18next";
import { useLayouts } from "../../../hooks/useLayouts";
import { Button } from "../../../components/ui/button";
import { useSession } from "../../../hooks/SessionProvider";
import { useRouter } from "../../../hooks/useRouter";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "../../../components/ui/card";
import { Components } from "../__containers/Components";
import { useState } from "react";
import NewLayoutDialog from "../__containers/NewLayoutDialog";
import CopyToClipboardButton from "../../../common/CopyToClipboardButton";

const AdminHome = ({ match, ...props }: any) => {
  const { user } = useSession();
  const router = useRouter();
  const { t: getTranslationByLabel } = useTranslation();
  const [showNewLayoutDialog, setShowNewLayoutDialog] =
    useState<boolean>(false);
  const { layouts, refresh } = useLayouts();

  return (
    <div className="space-y-6">
      <div>
        <div className="text-xl font-semibold">HallNews</div>
        <div className="text-muted-foreground text-base">
          Gérer les écrans connectés et leur contenu
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <div className="flex items-center justify-between">
              <div className="font-semibold text-lg">
                {getTranslationByLabel("hallnews-layouts")}
              </div>
              {user?.role === "MARVELOCK_ADMIN" && (
                <Button
                  size="sm"
                  variant="default"
                  onClick={() => setShowNewLayoutDialog(true)}
                >
                  {getTranslationByLabel("add")}
                </Button>
              )}
            </div>
            <CardDescription>
              Voici la liste de vos écrans connectés HallNews
            </CardDescription>
          </CardHeader>
          <CardContent>
            <table className="min-w-full text-left text-sm font-light">
              <thead className="border-b border-border font-medium">
                <tr className="pb-4 text-muted-foreground">
                  <th className="py-4 w-48">Location</th>
                  <th className="py-4">Composants</th>
                  <th className="py-4">Messages</th>
                  <th className="py-4"></th>
                </tr>
              </thead>
              <tbody>
                {layouts.map((layout) => {
                  const messages = layout.componentPlacements?.reduce<number>(
                    (acc, curr) => {
                      return acc + (curr.component?.content?.length ?? 0);
                    },
                    0
                  );
                  return (
                    <tr
                      key={layout.id}
                      className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                    >
                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {layout.location?.name}
                      </td>
                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {layout.componentPlacements?.length}
                      </td>
                      <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                        {messages}
                      </td>
                      <td>
                        <div className="flex justify-end">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() =>
                              router.push(
                                `/app/hallnews/${layout.location?.id}`
                              )
                            }
                          >
                            {getTranslationByLabel("manage")}
                          </Button>
                          <CopyToClipboardButton
                            valueToCopy={layout.id!.toString()}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardContent>
        </Card>
        <Components />
      </div>
      <NewLayoutDialog
        open={showNewLayoutDialog}
        onClose={() => {
          setShowNewLayoutDialog(false);
          refresh();
        }}
      />
    </div>
  );
};

export default AdminHome;
