import Cookies from "js-cookie";

interface RequestParam {
  method: "GET" | "POST" | "DELETE" | "PUT";
  url: string | URL;
  queryParam?: any;
  body?: any;
  authRequired?: Boolean;
  jwt?: string;
  jwtType?: "access" | "refresh";
  jwtPrefix?: string;
  credentials?: boolean;
}

export const apiFetch = async <T, U = T>({
  method,
  url,
  queryParam,
  body,
  authRequired = true,
  jwt,
  jwtType = "access",
  credentials,
  jwtPrefix = "Bearer",
}: RequestParam): Promise<U> => {
  /*
    Construct full url with query parameters
  */

  const fullUrl = new URL(url);
  if (queryParam && Object.keys(queryParam).length > 0) {
    Object.keys(queryParam).forEach((k) => {
      if (queryParam[k] !== undefined && queryParam[k] !== null) {
        fullUrl.searchParams.append(k, queryParam[k]);
      }
    });
  }

  let token = jwt ?? Cookies.get("mvl.access-token");

  if (jwtType === "refresh") {
    token = Cookies.get("mvl.refresh-token");
  }

  const originUrl = new URL(window.location.origin);
  const domain = originUrl.hostname.includes("localhost")
    ? ""
    : "." + originUrl.hostname;

  const fetchOptions: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: authRequired ? `${jwtPrefix} ${jwt ?? token}` : "",
      "X-Device-ID": `AdminPortal | ${navigator.userAgent}`,
      "X-Origin": domain,
    },
  };

  if (credentials) {
    fetchOptions.credentials = "include";
  }
  if (body) {
    fetchOptions.body = JSON.stringify(body);
  }

  const response = await fetch(fullUrl, fetchOptions);
  if (response.ok) {
    if (response.status !== 204) {
      const contentType = response.headers.get("Content-Type");
      if (contentType?.includes("csv")) {
        const content = await response.text();
        return content as U;
      } else {
        const data = (await response.json()) as T;
        return data as unknown as U;
      }
    } else {
      return null as unknown as U;
    }
  }

  const msg = await response.text();
  if (msg) {
    throw new Error(msg);
  }
  throw new Error(
    `[${response.status}] ${fullUrl.toString()} - ${response.statusText}`
  );
};

export const fetcher = <T>({
  url,
  queryParam,
}: {
  url: string;
  queryParam: any;
}): Promise<T> => {
  return apiFetch<T>({ method: "GET", url, queryParam });
};
