import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import {
  deleteLocation,
  submitLocation,
  SubmitLocationType,
} from "../../api/locations";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import { useOrganisations } from "../../hooks/useOrganisations";
import { Location } from "../../entities";
import toast from "react-hot-toast";
import Dropdown from "../Dropdown";
import { Controller, useForm } from "react-hook-form";
import Input from "../Input";
import { Checkbox } from "../ui/checkbox";

interface FormData extends SubmitLocationType {}

interface OwnProps {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onUpdate: () => void;
  location?: Location.Type;
}

const ModalLocationUpdate = ({
  open,
  onClose,
  onUpdate,
  onDelete,
  location,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const { organisations } = useOrganisations();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const COUNTRIES = useMemo(
    () => [
      getTranslationByLabel("france"),
      getTranslationByLabel("italy"),
      getTranslationByLabel("spain"),
      getTranslationByLabel("germany"),
      getTranslationByLabel("austria"),
      getTranslationByLabel("the-netherlands"),
      getTranslationByLabel("belgium"),
      getTranslationByLabel("united-kingdom"),
      getTranslationByLabel("ireland"),
      getTranslationByLabel("canada"),
      getTranslationByLabel("united-states"),
      getTranslationByLabel("switzerland"),
      getTranslationByLabel("bulgaria"),
    ],
    [getTranslationByLabel]
  );

  const defaultValues = useMemo<FormData>(
    () => ({
      latitude: undefined,
      longitude: undefined,
      country: getTranslationByLabel("france"),
      organization: organisations[0],
      companyName: "test",
      locationFeature: {
        lockerFeatureEnabled: false,
        notificationFeatureEnabled: false,
        smsFeatureEnabled: false,
      },
      ...location,
    }),
    [getTranslationByLabel, location, organisations]
  );

  const {
    control,
    handleSubmit,
    reset,

    formState: { isSubmitting, errors, isValid },
  } = useForm<FormData>({ mode: "all", defaultValues });

  useEffect(() => {
    if (open && !location) {
      reset({});
    }
  }, [open, location, reset]);

  const submit = async (data: FormData) => {
    try {
      if (
        !data?.locationFeature.lockerFeatureEnabled &&
        !data?.locationFeature.notificationFeatureEnabled
      ) {
        toast.error("At least one feature should be enabled");
        return;
      }

      data.companyName = data.organization.name;
      await submitLocation(data, !location);
      onUpdate();
    } catch (error: any) {
      console.error(error);
      console.log("Error while save location -> ", error);
    }
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleDelete = useCallback(async () => {
    if (!location) return;
    await deleteLocation(location.id);
    setDeleteDialogOpen(false);
    onDelete();
  }, [location, onDelete]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="w-8/12 max-w-none">
          <DialogHeader>
            <DialogTitle>
              {getTranslationByLabel(
                !!location ? "update-location" : "create-location"
              )}
            </DialogTitle>
          </DialogHeader>
          <div className="grid grid-cols-3 gap-6">
            <div className="flex flex-col items-stretch space-y-4">
              <Controller
                name="name"
                rules={{
                  required: { value: true, message: "Required" },
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    label={getTranslationByLabel("location-name")}
                    {...field}
                  />
                )}
              />
              <Controller
                name="organization"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field }) => (
                  <Dropdown
                    label={getTranslationByLabel("company")}
                    items={organisations.map((org) => ({
                      label: org.name,
                      value: org.id?.toString() ?? "",
                    }))}
                    {...field}
                    value={field.value?.id?.toString() ?? ""}
                    onChange={(e: any) => {
                      const orga = organisations.find((v) => v.id === e);
                      if (orga) field.onChange(orga);
                      else field.onChange(null);
                    }}
                    disableClearing
                  />
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Required",
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="tel"
                    label={getTranslationByLabel("phone-number")}
                    {...field}
                  />
                )}
              />

              <Controller
                name="information"
                control={control}
                render={({ field }) => (
                  <Input
                    label={getTranslationByLabel("information")}
                    {...field}
                  />
                )}
              />

              <Controller
                name="image"
                control={control}
                render={({ field }) => (
                  <div className="flex flex-col  space-y-2">
                    <div className="flex-grow">
                      <Input label={"Image"} {...field} />
                    </div>
                    <div className="p-3 w-20 h-20 rounded border bg-muted">
                      {field.value && (
                        <img
                          className="w-full h-full object-cover"
                          src={field.value}
                          alt="location"
                        />
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 h-fit">
              <div className="col-span-2">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      label={getTranslationByLabel("address")}
                      {...field}
                    />
                  )}
                />
              </div>

              <Controller
                name="building"
                control={control}
                render={({ field }) => (
                  <Input label={getTranslationByLabel("building")} {...field} />
                )}
              />
              <Controller
                name="floor"
                control={control}
                render={({ field }) => (
                  <Input
                    type="number"
                    label={getTranslationByLabel("floor")}
                    {...field}
                  />
                )}
              />

              <div className="col-span-2">
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field }) => (
                    <Input label={getTranslationByLabel("city")} {...field} />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  name="country"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Required",
                    },
                  }}
                  render={({ field }) => (
                    <Dropdown
                      label={getTranslationByLabel("country")}
                      items={COUNTRIES.map((country) => ({
                        label: country,
                        value: country,
                      }))}
                      disableClearing
                      {...field}
                    />
                  )}
                />
              </div>

              <Controller
                name="latitude"
                control={control}
                render={({ field }) => (
                  <Input label={getTranslationByLabel("latitude")} {...field} />
                )}
              />
              <Controller
                name="longitude"
                control={control}
                render={({ field }) => (
                  <Input
                    label={getTranslationByLabel("longitude")}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="flex flex-col ">
              <div className="flex-grow space-y-2">
                <Controller
                  name="locationFeature.lockerFeatureEnabled"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <div className="text-sm ">
                        {getTranslationByLabel("locker-feature-enabled")}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="locationFeature.notificationFeatureEnabled"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <div className="text-sm ">
                        {getTranslationByLabel("notification-feature-enabled")}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="locationFeature.smsFeatureEnabled"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <div className="text-sm ">
                        {getTranslationByLabel("sms-feature-enabled")}
                      </div>
                    </div>
                  )}
                />
              </div>
              <div>
                {Object.keys(errors).map((k) => (
                  <div
                    key={k}
                    className="flex justify-end space-x-2 text-destructive text-sm"
                  >
                    <span className="font-light ">{k}</span> :{" "}
                    <span className=" ">
                      {errors[k as keyof typeof errors]?.message}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <DialogFooter>
            {!!location && (
              <Button
                variant="destructive-outline"
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              >
                {getTranslationByLabel("delete")}
              </Button>
            )}

            <Button
              variant="default"
              disabled={!isValid}
              onClick={handleSubmit(submit)}
            >
              {getTranslationByLabel("submit")}
            </Button>
          </DialogFooter>
        </DialogContent>

        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          // disabled={isSubmitting}
          onConfirm={handleDelete}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default ModalLocationUpdate;
