import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { fetchSelfUser } from "../api/user";
import { User } from "../entities";
import { EnumNotificationType, showNotification } from "./notificationSlice";

export interface StateItems {
  [id: string]: User.Type;
}

interface UserState {
  self?: User.Self;
  shouldHardLogout: boolean;
  items: StateItems;
  pages: number;
  elements: number;
  loading: boolean;
  error?: string;
}

const initialState: UserState = {
  loading: false,
  pages: 0,
  elements: 0,
  shouldHardLogout: false,
  items: {},
};

// First, create the thunk
export const getSelfUser = createAsyncThunk<User.Self, string | undefined>(
  "user/getSelfUser",
  async (token, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setFetchUserError());
      const userRaw = await fetchSelfUser();
      if (!userRaw) {
        return rejectWithValue("Something went wrong. Please try again later.");
      }
      const user: User.Self = {
        ...(User.parseRawUser(userRaw) as User.Self),
        accessToken: token,
      };
      return user;
    } catch (error: any) {
      if (error.response?.status !== 401) {
        dispatch(
          showNotification({
            text: "Something went wrong. Please try again later.",
            type: EnumNotificationType.Error,
          })
        );
      }
      return rejectWithValue(error.response?.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setFetchUserError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setShouldHardLogout: (state, action: PayloadAction<boolean>) => {
      state.shouldHardLogout = action.payload;
    },
    clearState: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSelfUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSelfUser.fulfilled, (state, action) => {
      state.self = action.payload;
      state.loading = false;
    });
    builder.addCase(getSelfUser.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setFetchUserError, clearState, setShouldHardLogout } =
  userSlice.actions;
export const selectSelfUser = (state: RootState) => state.user.self;
export const selectFetchUserError = (state: RootState) => state.user.error;
export const selectItems = (state: RootState) => {
  return Object.keys(state.user.items).map((key) => state.user.items[key]);
};
export const selectPages = (state: RootState) => state.user.pages;
export const selectIsLoading = (state: RootState) => state.user.loading;
export const selectItemsCount = (state: RootState) => state.user.elements;
export const selectItemById = (state: RootState, id?: string) => {
  if (!id) {
    return null;
  }
  return state.user.items[id];
};
export default userSlice.reducer;
