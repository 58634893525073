import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import IconButton from "@mui/material/IconButton";

import { Col, Div, Row } from "../../../styles/layout";
import { Text } from "../../../styles/typography";
import { useMemo, useState } from "react";
import Button from "../../../components/Button";

import { ChatBubbleIcon } from "@radix-ui/react-icons";
import { getCurrentTheme } from "../../../styles/theme";
import { useComponents } from "../../../hooks/useComponents";
import { ClickableCard } from "../../../components/ClickableCard";
import { create as createPlacement } from "../../../services/placements";
import Layout from "../../../entities/Layout";
import HallnewsComponentPlacement from "../../../entities/HallnewsComponentPlacement";
import HallnewsComponent from "../../../entities/HallnewsComponent";
import { useTranslation } from "react-i18next";

const ComponentCard = ({ component, selected, onSelect }: any) => {
  const { t: getTranslationByLabel } = useTranslation();

  return (
    <ClickableCard onClick={onSelect}>
      <Row
        borderRadius="1"
        bg="bgBase"
        boxShadow="0 2px 3px 0px rgba(0,0,0,0.1)"
        p="3"
        alignItems={"center"}
        mb="5"
        position="relative"
        backgroundColor={selected ? "elementActive" : "bgBase"}
        border={
          selected
            ? `1px solid ${getCurrentTheme().colors.accent}`
            : `1px solid transparent}`
        }
      >
        <Col
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          bg="element"
          width={56}
          height={56}
        >
          <ChatBubbleIcon width={32} height={32} />
        </Col>
        <Col p="3">
          <Text color="textHigh" fontSize="18px">
            {component.label}
          </Text>
          <Text color="textLow" fontWeight="300" fontSize="16px">
            {component.content?.length
              ? `${component.content.length} messages`
              : getTranslationByLabel("hallnews-component-empty")}
          </Text>
        </Col>
      </Row>
    </ClickableCard>
  );
};

const AttachComponentsDialog = ({
  open,
  onClose,
  layout,
  placements,
}: {
  open: boolean;
  onClose: () => void;
  layout: Layout.Type;
  placements: HallnewsComponentPlacement.Type[];
}) => {
  const { t: getTranslationByLabel } = useTranslation();

  const organisationId = layout.location?.organization.id;
  const [selectedComponents, setSelectedComponents] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { components } = useComponents(
    0,
    100,
    organisationId ? [organisationId] : undefined
  );

  const availableComponents = useMemo(() => {
    return components.filter(
      (component: HallnewsComponent.Type) =>
        !placements.find((p) => p.component!.id === component.id)
    );
  }, [placements, components]);

  const handleClose = () => {
    setSelectedComponents([]);
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const responses = await Promise.all(
        selectedComponents.map((comp, idx) =>
          createPlacement({
            layout,
            component: comp,
            gridRow: idx,
            gridCol: idx,
            gridColSpan: 1,
            gridRowSpan: 1,
          })
        )
      );
      console.log(responses);
      handleClose();
    } catch (e: any) {
      console.error(e);
      setError(e);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        className="user-dialog"
      >
        <DialogTitle>
          <Col position="relative" mb="5">
            <Text
              fontWeight="600"
              fontSize="24px"
              color="textLow"
              fontFamily="Avenir-Black"
            >
              {getTranslationByLabel("hallnews-layout-attach-header")}
            </Text>

            <Div position="absolute" right="8px">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                color="primary"
              >
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Div>
          </Col>
        </DialogTitle>
        <DialogContent sx={{ overflow: "hidden", overflowY: "auto" }}>
          <Col>
            {availableComponents.map((component: any) => (
              <ComponentCard
                component={component}
                selected={
                  !!selectedComponents.find((c) => c.label === component.label)
                }
                onSelect={() =>
                  setSelectedComponents((a) => {
                    if (a.find((ac) => ac.label === component.label)) {
                      return a.filter((c) => c.label !== component.label);
                    } else {
                      return [...a, component];
                    }
                  })
                }
              />
            ))}
          </Col>
        </DialogContent>
        <DialogActions>
          <Col>
            <Text color="danger.textLow">{error}</Text>

            <Row justifyContent="flex-end" mt="4">
              <Button
                variant="primaryOutline"
                label={getTranslationByLabel("cancel")}
                mr="3"
                onClick={() => onClose()}
              />
              <Button
                variant="primary"
                label={getTranslationByLabel("add")}
                onClick={handleSubmit}
              />
            </Row>
          </Col>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AttachComponentsDialog;
