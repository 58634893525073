import themeGet from "@styled-system/theme-get";

import styled from "styled-components";
import {
  position,
  flexbox,
  layout,
  space,
  color,
  border,
  typography,
  shadow,
  grid,
  PositionProps,
  LayoutProps,
  ColorProps,
  FlexboxProps,
  BorderProps,
  TypographyProps,
  ShadowProps,
  GridProps,
  SpaceProps,
} from "styled-system";
import { animated } from "react-spring";

export interface StyledProps
  extends ColorProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    GridProps {
  children?: React.ReactNode;
  objectFit?: string;
}

export const Div = styled.div<StyledProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}  
  ${grid}
`;

export const Button = styled.button<StyledProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}
  outline: none;
  border: none;
  background-color: ${themeGet("colors.accent")};
  box-shadow: 0 0 1px 0.5px ${themeGet("colors.borderSubtle")};
  color: ${themeGet("colors.text.accent.high")};

  &:hover {
    background-color: ${themeGet("colors.accentHover")};
  }

  &:focus {
    background-color: ${themeGet("colors.accentHover")};
  }
`;

export const Col = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${typography}
  ${shadow}
  ${grid}
`;

export const Row = styled.div<StyledProps>`
  display: flex;
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
  ${grid}
`;

export const AnimatedRow = styled(animated.div)<StyledProps>`
  display: flex;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}  
  ${grid}
`;

export const AnimatedCol = styled(animated.div)<StyledProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}  
  ${grid}
`;

export const Image = styled.img<StyledProps>`
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  user-select: none;
`;

export const StyledInput = styled.input<StyledProps>`
  ${layout}
  ${space}
  ${color}
  ${border}
  ${typography}
  outline: none;
  border: none;
  background-color: ${themeGet("colors.element")};

  &:hover {
    background-color: ${themeGet("colors.elementHover")};
  }
  &:focus {
    background-color: ${themeGet("colors.elementActive")};
  }
`;

export const Hoverable = styled.div<StyledProps>`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
  ${grid}
 
  &:hover {
    cursor: pointer;
  }
`;
