import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { SystemNotification } from "../entities";

export interface SystemNotificationResponse extends PartialApiItemsResponse {
  content: SystemNotification.Type[];
}

export const fetchSystemNotifications = (
  page: number,
  items: number,
  isResident: boolean,
  status?: SystemNotification.State
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sort", "created_at");
  urlSearchParams.append("sortDirection", "DESC");
  if (status) {
    urlSearchParams.append("state", status.toUpperCase());
  }
  let url = "/v1/notifications";
  if (isResident) {
    url = "/v1/user/notifications";
  }
  return new Promise<SystemNotificationResponse>((resolve, reject) => {
    axios
      .get(`${url}?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitSystemNotification = (
  item: Partial<SystemNotification.Type>,
  isNew?: boolean
) => {
  const itemToSubmit = {
    ...item,
    effectiveDate: item.effectiveDate?.utc(true).format(),
    termDate: item.termDate?.utc(true).format(),
    archive: false,
  };
  if (isNew) {
    return axios.post("/v1/notifications", itemToSubmit);
  } else {
    return axios.put(`/v1/notifications/${itemToSubmit.id}`, itemToSubmit);
  }
};

export const archiveSystemNotification = (
  item: Partial<SystemNotification.Type>
) => {
  return axios.put(`/v1/notifications/${item.id}`, { ...item, archived: true });
};

export const deleteSystemNotification = (id: string) => {
  return axios.delete(`/v1/notifications/${id}`);
};
