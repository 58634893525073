import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { ServiceOrder } from "../entities";

export interface ServiceOrderResponse extends PartialApiItemsResponse {
  content: ServiceOrder.Type[];
}

export const fetchServiceOrders = (
  page: number,
  items: number,
  filters?: ServiceOrder.State[]
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sort", "effective_date");
  urlSearchParams.append("sortDirection", "DESC");
  if (filters) {
    filters.forEach((state) => {
      urlSearchParams.append("state", state);
    });
  }
  return new Promise<ServiceOrderResponse>((resolve, reject) => {
    axios
      .get(`/v2/service-orders?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchServiceOrderById = (id: string) => {
  return new Promise<ServiceOrder.Type>((resolve, reject) => {
    axios
      .get(`/v1/service-orders/${id}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};
