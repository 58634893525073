import axios from "axios";

import Cookies from "js-cookie";

export interface PartialApiItemsResponse {
  totalElements: number;
  totalPages: number;
}

export const configureAxios = () => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;
  axios.interceptors.request.use(
    (config) => {
      const token = Cookies.get("mvl.access-token");
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
  );
};
