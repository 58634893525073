import { useCallback, useMemo } from "react";
import { HallNewsUserType, RoleLevels } from "../entities/User";
import { useAppSelector } from "../hooks";
import { selectSelfUser } from "../store/userSlice";
import { useSelfUser } from "./useSelfUser";

export function useUser() {
  const { user } = useSelfUser(true);
  const isAuthorized = useCallback(
    (refRole: HallNewsUserType) => {
      if (user) {
        const userRole = user.role;
        const userRoleLevel = RoleLevels[userRole as HallNewsUserType];
        const refRoleLevel = RoleLevels[refRole];
        return userRoleLevel <= refRoleLevel;
      }
      return false;
    },
    [user]
  );

  const lowerRoles = useMemo(() => {
    if (user) {
      const userRole = user.role;
      const userRoleLevel = RoleLevels[userRole as HallNewsUserType];
      const lowerRoles = Object.keys(RoleLevels).filter(
        (r: string) => (RoleLevels as any)[r] > userRoleLevel
      );
      return lowerRoles as HallNewsUserType[];
    }
    return [];
  }, [user]);

  return { user, isAuthorized, lowerRoles };
}
