import Input from "../../../components/Input";
import { useState } from "react";
import Layout from "../../../entities/Layout";
import { update } from "../../../services/layouts";
import { useTranslation } from "react-i18next";
import { Button } from "../../../components/ui/button";
import { CountPicker } from "../../../components/CountPicker";
import { Label } from "../../../components/Label";
import { CardTitle } from "../../../components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../../components/ui/dialog";

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  layout: Layout.Type;
  onUpdate: () => void;
}

const LayoutConfig = ({ layout, onUpdate, onClose, isOpen }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  const [gridRows, setGridRows] = useState<number>(layout.gridRows);
  const [gridCols, setGridCols] = useState<number>(layout.gridCols);
  const [imageUrl, setImageUrl] = useState<string | undefined>(layout.imageUrl);

  const submit = async () => {
    try {
      console.log("Updating layout");
      const response = await update({
        id: layout.id,
        gridRows,
        gridCols,
        imageUrl,
        gutter: layout.gutter,
        margin: layout.margin,
        headerHeight: layout.headerHeight,
        footerHeight: layout.footerHeight,
      });
      console.log(response);
      onUpdate();
      onClose();
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <CardTitle className="text-lg">Configuration de l'écran</CardTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4 pb-6 rounded border p-4 max-w-xl">
          <Input
            type="text"
            label={"Image"}
            value={layout.imageUrl || layout.location?.image}
            min={1}
            max={6}
            onChange={(v: string) => {
              setImageUrl(v);
            }}
            labelStyle={{
              fontWeight: 700,
              color: "textLow",
              fontSize: "16px",
            }}
            inputStyle={{ fontSize: "14px" }}
          />
          <div>
            <Label label={getTranslationByLabel("hallnews-layout-rows")} />
            <CountPicker
              max={6}
              value={gridRows}
              onChange={(v: number) => {
                setGridRows(v);
              }}
            />
          </div>
          <div>
            <Label label={getTranslationByLabel("hallnews-layout-cols")} />
            <CountPicker
              max={6}
              value={gridCols}
              onChange={(v: number) => {
                setGridCols(v);
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={submit}>{getTranslationByLabel("save")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LayoutConfig;
