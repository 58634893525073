import { Box, Pagination } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useLocationFilters } from "../../hooks/useLocationFilters";
import { usePagination } from "../../hooks/usePagination";
import { useLocations } from "../../hooks/useLocations";
import { Location } from "../../entities";
import { ClipLoader } from "react-spinners";
import { formatDate } from "date-fns";
import CopyToClipboardButton from "../../common/CopyToClipboardButton";
import { Button } from "../../components/ui/button";
import ModalLocationUpdate from "../../components/Modal/ModalLocationUpdate";
import ItemsFilter from "../../components/ItemsFilter";
import { LucidePlus } from "lucide-react";
import { PageContent, PageHeader, PageTitle } from "../../components/ui/page";

const ITEMS_PER_PAGE = 10;

const Locations = () => {
  const { t: getTranslationByLabel } = useTranslation();
  const routerLocation = useLocation();

  // const count = useAppSelector(selectItemsCount);
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const { sortDirection, organization } = useLocationFilters();
  const [selectedLocation, setSelectedLocation] = useState<
    Location.Type | undefined
  >();

  const query = useMemo(() => {
    const query: any = { sortDirection: "DESC", sort: "createdAt" };
    if (organization) query.organization = organization;
    if (sortDirection) query.sortDirection = sortDirection;
    return query;
  }, [sortDirection, organization]);

  const { locations, pages, refresh, isLoading } = useLocations(
    (page ?? 0) - 1,
    ITEMS_PER_PAGE,
    query
  );

  return (
    <>
      <PageHeader>
        <PageTitle>{getTranslationByLabel("locations-menu")}</PageTitle>
        <div className="flex items-center space-x-4 justify-between">
          <ItemsFilter />
          <div className="flex items-center space-x-4">
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, routerLocation.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
            <Button
              onClick={() => setDialogOpened(true)}
              variant="default"
              className="space-x-2"
            >
              <LucidePlus size={16} />
              <span>{getTranslationByLabel("create-new")}</span>
            </Button>
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <>
          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b border-border font-medium">
              <tr className="pb-4 text-muted-foreground">
                <th className="py-4 w-32">Ajouté le</th>
                <th className="py-4">Nom</th>
                <th className="py-4">Organisation</th>
                <th className="py-4">Adresse</th>
                <th className="py-4"></th>
              </tr>
            </thead>
            <tbody>
              {locations.map((location: Location.Type) => (
                <tr
                  key={location.id}
                  className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                >
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {formatDate(new Date(location.createdAt), "dd/MM/yyyy")}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {location.name}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {location.organization.name}
                  </td>
                  <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                    {location.address
                      ? `${location.address}, ${location.city}, ${location.country}`
                      : "Non renseignée"}
                  </td>
                  <td className="">
                    <div className="flex items-center justify-end space-x-4">
                      <CopyToClipboardButton valueToCopy={location.id} />
                      <Button
                        size="sm"
                        onClick={() => {
                          setDialogOpened(true);
                          setSelectedLocation(location);
                        }}
                      >
                        {getTranslationByLabel("edit")}
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              {locations.length === 0 && !isLoading && (
                <tr>
                  <td colSpan={8} className="py-4 text-center">
                    Aucun emplacement trouvé
                  </td>
                </tr>
              )}
              {locations.length === 0 && isLoading && (
                <tr>
                  <td colSpan={8} className="py-6 text-center">
                    <ClipLoader size="medium" color="secondary" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Box display="flex" alignItems="center" justifyContent="center" p={1}>
            <Pagination
              onChange={(_, page) => {
                // Decrease and increase with 1, because the API has zero based pagination filter
                setPage(page, routerLocation.search);
              }}
              page={page ?? 1}
              count={pages}
              variant="outlined"
              color="primary"
              shape="rounded"
            />
          </Box>
        </>
      </PageContent>

      <ModalLocationUpdate
        open={dialogOpened || !!selectedLocation}
        location={selectedLocation}
        onClose={() => {
          setSelectedLocation(undefined);
          setDialogOpened(false);
          refresh();
        }}
        onUpdate={() => {
          setSelectedLocation(undefined);
          setDialogOpened(false);
          refresh();
        }}
        onDelete={() => {
          setSelectedLocation(undefined);
          setDialogOpened(false);
          refresh();
        }}
      />
    </>
  );
};

export default Locations;
