import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const PAGE_FILTER = "page";

export function usePagination(): [
  number | undefined,
  (page: number, search: string) => void
] {
  const location = useLocation();
  const history = useHistory();
  const [page, _setPage] = useState<number>(1);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    if (urlSearchParams.has(PAGE_FILTER)) {
      _setPage(Number(urlSearchParams.get(PAGE_FILTER)));
    } else {
      _setPage(1);
    }
  }, [location.search]);

  const setPage = useCallback(
    (newPage: number, search: string) => {
      const urlSearchParams = new URLSearchParams(search);
      if (newPage === 1) {
        urlSearchParams.delete(PAGE_FILTER);
      } else {
        urlSearchParams.set(PAGE_FILTER, String(newPage));
      }
      history.replace({
        pathname: location.pathname,
        search: urlSearchParams.toString(),
      });
    },
    [history, location.pathname]
  );

  return useMemo(() => [page, setPage], [page, setPage]);
}
