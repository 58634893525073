//time-picker.tsx
import { type DateType, useTimescape } from "timescape/react";
import * as React from "react";
import {
  createContext,
  forwardRef,
  type HTMLAttributes,
  type ReactNode,
  useContext,
} from "react";
import type { Options } from "timescape";
import { cn } from "../../lib/utils";

export type TimePickerProps = HTMLAttributes<HTMLDivElement> & {
  value?: Date;
  refDate?: Date;
  onChange: (date?: Date) => void;
  children: ReactNode;
  options?: Omit<Options, "date" | "onChangeDate">;
};
type TimePickerContextValue = ReturnType<typeof useTimescape>;
const TimePickerContext = createContext<TimePickerContextValue | null>(null);

const useTimepickerContext = (): TimePickerContextValue => {
  const context = useContext(TimePickerContext);
  if (!context) {
    throw new Error(
      "Unable to access TimePickerContext. This component should be wrapped by a TimePicker component"
    );
  }
  return context;
};

const TimePicker = forwardRef<React.ElementRef<"div">, TimePickerProps>(
  ({ value, refDate, onChange, options, className, ...props }, ref) => {
    const handleChange = React.useCallback(
      (newDate: Date | undefined) => {
        if (!newDate) {
          onChange(undefined);
          return;
        }
        if (!refDate) {
          onChange(newDate);
          return;
        }
        refDate.setHours(newDate.getHours(), newDate.getMinutes());
        onChange(refDate);
      },
      [onChange, refDate]
    );

    const timePickerContext = useTimescape({
      date: value ?? new Date(),
      onChangeDate: handleChange,
      ...options,
    });

    React.useEffect(() => {
      if (value) {
        timePickerContext.update((prev) =>
          prev === value ? prev : { date: value }
        );
      }
      // Otherwise infinite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <TimePickerContext.Provider value={timePickerContext}>
        <div
          ref={ref}
          {...props}
          className={cn(
            "flex h-10 w-fit rounded-md border border-input bg-background px-3 py-1 text-sm",
            " focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
            "disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
        >
          {props.children}
        </div>
      </TimePickerContext.Provider>
    );
  }
);
TimePicker.displayName = "TimePicker";

type TimePickerSegmentProps = Omit<
  HTMLAttributes<HTMLInputElement>,
  "value" | "onChange"
> & {
  segment: DateType;
  inputClassName?: string;
};

const TimePickerSegment = forwardRef<
  React.ElementRef<"input">,
  TimePickerSegmentProps
>(({ segment, inputClassName, className, ...props }, ref) => {
  const { getInputProps } = useTimepickerContext();
  const { ref: timePickerInputRef } = getInputProps(segment);
  return (
    <div
      className={cn(
        "w-8 rounded-md px-2 py-1 text-accent-foreground focus-within:bg-accent"
      )}
    >
      <input
        ref={(node) => {
          if (typeof ref === "function") {
            ref(node);
          } else {
            if (ref) ref.current = node;
          }
          timePickerInputRef(node);
        }}
        {...props}
        className={cn(
          "tabular-nums caret-transparent",
          "border-transparent bg-transparent outline-none ring-0 ring-offset-0 focus-visible:border-transparent focus-visible:ring-0",
          inputClassName
        )}
      />
    </div>
  );
});
TimePickerSegment.displayName = "TimePickerSegment";

type TimePickerSeparatorProps = HTMLAttributes<HTMLSpanElement>;
const TimePickerSeparator = forwardRef<
  React.ElementRef<"span">,
  TimePickerSeparatorProps
>(({ className, ...props }, ref) => {
  return (
    <span ref={ref} {...props} className={cn("py-1 text-sm", className)}></span>
  );
});
TimePickerSeparator.displayName = "TimePickerSeparator";

export { TimePicker, TimePickerSegment, TimePickerSeparator };
