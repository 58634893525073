import { sortByCreatedAt } from "./../lib/utils";
import { routes } from "../services/components";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import HallnewsComponent from "../entities/HallnewsComponent";

interface ComponentsDTO {
  content: HallnewsComponent.Type[];
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: 1;
  last: boolean;
}

export function useComponents(
  page = 0,
  limit = 1000,
  organisationIds: string[] = []
) {
  const revalidationEnabled = page === 0;
  const { data, error, mutate } = useSWR(
    {
      url: routes.list,
      queryParam: {
        page,
        items: limit,
        organisationIds: organisationIds.join(","),
        sort: "createdAt",
        sortDirection: "DESC",
      },
    },
    fetcher<ComponentsDTO>,
    {
      revalidateIfStale: revalidationEnabled,
      revalidateOnFocus: revalidationEnabled,
      revalidateOnReconnect: revalidationEnabled,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    components: data?.content ?? [],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
