import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { User } from "../entities";

export interface UsersResponse extends PartialApiItemsResponse {
  content: User.Type[];
}

export const fetchSelfUser = (token?: string) => {
  return new Promise<User.Self>((resolve, reject) => {
    axios
      .get("/v2/users/self")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchUsers = (
  page: number,
  items: number,
  roles?: User.UserType[],
  firstName?: string,
  lastName?: string
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sortDirection", "ASC");
  if (roles && roles.length) {
    urlSearchParams.append("roles", roles?.join(","));
  }
  if (firstName) {
    urlSearchParams.append("first-name", firstName);
  }
  if (lastName) {
    urlSearchParams.append("last-name", lastName);
  }
  return new Promise<UsersResponse>((resolve, reject) => {
    axios
      .get(`/v2/users?${urlSearchParams.toString()}`)
      .then((response) => {
        const content: User.Type[] = response.data.content.map(
          User.parseRawUser
        );
        resolve({
          ...response.data,
          content,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const mockUser = {
  id: "08df6744-803d-4fec-bb6b-c6b4e3c5f4b7",
  firstName: "Marvelock",
  lastName: "UserOne",
  email: "marvelock.user.1@gmail.com",
  type: "SKIER",
  organization: "END_USER",
  isEnabled: true,
  locations: [],
};

export const fetchUserById = (id: string) => {
  return new Promise<User.Type>((resolve) => {
    setTimeout(() => {
      resolve(User.parseRawUser(mockUser) as User.Type);
    }, 1000);
  });
  // return new Promise<User.Type>((resolve, reject) => {
  //   axios
  //     .get(`/v2/users/${id}`)
  //     .then((response) => {
  //       const content: User.Type[] = response.data.content.map(
  //         User.parseRawUser
  //       );
  //       resolve({
  //         ...response.data,
  //         content,
  //       });
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
};

export interface SubmitUserType
  extends Partial<Omit<User.Type, "userConfiguration">> {
  locations: User.Type["locations"];
  locationACLs: User.Type["locationACLs"];
  userConfiguration?: Partial<User.UserConfiguration>;
}

export const submitUser = (
  item: SubmitUserType,
  isNew: boolean,
  canUpdateRole: boolean
) => {
  if (isNew) {
    return axios.post("/v2/users", {
      ...item,
      organization: "ECONCIERGE",
      isHandicap: item.isHandicap ?? false,
    });
  } else {
    if (!canUpdateRole) {
      delete item.type;
    }
    return axios.put(`/v2/users/${item.id}`, item);
  }
};

export const deleteUser = (id: string) => {
  return axios.delete(`/v2/users/${id}`);
};
