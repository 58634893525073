import { Route } from "react-router-dom";

import HallnewsHome from "./HallnewsHome";
import HallnewsLayout from "./HallnewsLayout";
import HallnewsComponent from "./HallnewsComponent";
import { PageContent, PageHeader, PageTitle } from "../../components/ui/page";

const HallnewsRoutes = () => {
  return (
    <>
      <PageContent>
        <Route exact path={"/app/hallnews"} component={HallnewsHome} />
        <Route
          exact
          path={"/app/hallnews/:locationId"}
          component={HallnewsLayout}
        />
        <Route
          exact
          path={"/app/hallnews/widgets/:componentId"}
          component={HallnewsComponent}
        />
      </PageContent>
    </>
  );
};

export default HallnewsRoutes;
