import Organisation from "../entities/Organisation";
import { apiFetch } from "./../lib/apiFetch";
const API = process.env.REACT_APP_API_URL;

export const routes = {
  list: `${API}/v1/organizations`,
  findById: (id: string) => `${API}/organizations/${id}`,
};

export const create = (body: Organisation.Type) =>
  apiFetch({
    method: "POST",
    url: `${API}/v1/organizations`,
    body,
    authRequired: true,
  });

export const update = (body: Organisation.Type) =>
  apiFetch({
    method: "PUT",
    url: `${API}/v1/organizations/${body.id}`,
    body,
    authRequired: true,
  });
