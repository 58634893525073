import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

import "./style.scss";
import Input from "../Input";
import { Col } from "../../styles/layout";
import { Text } from "../../styles/typography";
import Button from "../Button";
import { motion } from "framer-motion";
import { useSession } from "../../hooks/SessionProvider";

interface OTPDetails {
  otp: string;
}

const OTPForm = ({
  email,
  onComplete,
}: {
  email: string;
  onComplete: () => void;
}) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const { signIn } = useSession();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<OTPDetails>({ mode: "all" });

  const submit = async (data: OTPDetails) => {
    try {
      setError(undefined);
      await signIn(data.otp);
      // router.push({ pathname: "/signin/auth", query: { email: data.email } });
    } catch (e: any) {
      console.warn(e.message);
      setError("Invalid or expired codes");
    }
  };
  return (
    <motion.div
      initial={{ x: 400 }}
      animate={{ x: 0 }}
      exit={{ x: -400 }}
      transition={{
        type: "spring",
        duration: 0.2,
        stiffness: 450,
        damping: 35,
      }}
    >
      <Col alignItems={"stretch"}>
        <Col flexGrow="1" py="32px" alignItems="stretch">
          <Text fontSize="24px" fontWeight="600" color="mvl.primary" mb="24px">
            {getTranslationByLabel("auth-otp-title")}
          </Text>
          <form onSubmit={handleSubmit(submit)}>
            <Col mb="44px" alignItems="stretch">
              <Controller
                name="otp"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    label="Code"
                    type="text"
                    containerStyle={{ marginBottom: "16px" }}
                    {...field}
                  />
                )}
              />

              {error && (
                <Text
                  color="danger.textLow"
                  textAlign={"center"}
                  mb="3"
                  fontSize="18px"
                >
                  {error}
                </Text>
              )}
            </Col>

            <Button
              type="submit"
              label={getTranslationByLabel("auth-otp-button")}
              containerStyle={{
                bg: "mvl.primary",
                height: 54,
                width: "100%",
                borderRadius: "1",
                px: 24,
              }}
              labelStyle={{ color: "opposite.textHigh", fontSize: 18 }}
              isLoading={isSubmitting}
            />
          </form>

          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
        </Col>
      </Col>
    </motion.div>
  );
};
export default OTPForm;
