import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from ".";
import { fetchServiceOrders, ServiceOrderResponse } from "../api/serviceOrders";
import { ServiceOrder, User } from "../entities";
import { EnumNotificationType, showNotification } from "./notificationSlice";

export interface ServiceOrdersState {
  items: ServiceOrder.Type[];
  pages: number;
  elements: number;
  loading: boolean;
  error?: string;
}

const initialState: ServiceOrdersState = {
  items: [],
  pages: 0,
  elements: 0,
  loading: false,
};

export const getServiceOrders = createAsyncThunk<
  ServiceOrderResponse,
  { page: number; filters?: ServiceOrder.State[] },
  {
    state: RootState;
  }
>(
  "serviceOrders/getServiceOrders",
  async ({ page, filters }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setFetchServiceOrdersError());
      dispatch(setLoading(true));
      const response = await fetchServiceOrders(page, 10, filters);
      const now = moment();
      const content: ServiceOrder.Type[] = response.content.map((el) => {
        let state = el.state;
        if (state === "active") {
          const effectiveDate = moment(el.effectiveDate);
          console.log("Effective date => ", effectiveDate.toString());
          const diff = now.diff(effectiveDate, "days");
          if (diff >= 2) {
            state = "attention";
          }
        }
        return {
          ...el,
          effectiveDate: moment(el.effectiveDate),
          user: el.user ? (User.parseRawUser(el.user) as User.Type) : undefined,
          state,
        };
      });
      return {
        ...response,
        content,
      };
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const loading = getState().serviceOrders.loading;
      return !loading;
    },
  }
);

export const serviceOrdersSlice = createSlice({
  name: "serviceOrders",
  initialState,
  reducers: {
    setServiceOrders: (state, action: PayloadAction<ServiceOrder.Type[]>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchServiceOrdersError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServiceOrders.fulfilled, (state, action) => {
      state.items = action.payload.content;
      state.pages = action.payload.totalPages;
      state.elements = action.payload.totalElements;
      state.loading = false;
    });
    builder.addCase(getServiceOrders.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setServiceOrders, setFetchServiceOrdersError, setLoading } =
  serviceOrdersSlice.actions;
export const selectItems = (state: RootState) => state.serviceOrders.items;
export const selectPages = (state: RootState) => state.serviceOrders.pages;
export const selectIsLoading = (state: RootState) =>
  state.serviceOrders.loading;
export const selectItemsCount = (state: RootState) =>
  state.serviceOrders.elements;
export const selectFetchItemsError = (state: RootState) =>
  state.serviceOrders.error;

export default serviceOrdersSlice.reducer;
