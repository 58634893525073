import { routes } from "../services/components";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import HallnewsComponent from "../entities/HallnewsComponent";

export function useComponent(componentId: number) {
  const { data, error, mutate } = useSWR(
    { url: routes.findById(componentId), queryParam: {} },
    fetcher<HallnewsComponent.Type>,
    {
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    component: data,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
