import { createTheme } from "@mui/material/styles";
import * as Colors from "./colors";
import type {} from "@mui/x-date-pickers/themeAugmentation";

const theme = createTheme({
  components: {
    MuiDialogContent: {
      defaultProps: {
        style: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        style: {
          fontFamily: "Poppins",
          fontSize: 14,
        },
      },
      styleOverrides: {
        contained: {
          color: Colors.TEXT,
        },
        outlined: {
          color: Colors.PRIMARY,
          borderColor: Colors.BUTTON_BORDER_COLOR,
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        style: {
          marginTop: 0,
          fontSize: 12,
          lineHeight: 1.4,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        style: {
          fontFamily: "Poppins",
          fontSize: 16,
          fontWeight: "normal",
        },
      },
    },
  },
  palette: {
    primary: {
      main: Colors.PRIMARY,
      light: Colors.PRIMARY_VARIANT,
    },
    secondary: {
      main: Colors.SECONDARY,
      light: Colors.SECONDARY_VARIANT,
    },
    background: {
      default: Colors.BACKGROUND,
    },
    error: {
      main: Colors.ERROR,
    },
    text: {
      primary: Colors.PRIMARY,
      secondary: Colors.TEXT_SECONDARY,
    },
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontFamily: "Poppins",
      fontSize: 96,
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: 60,
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: 48,
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: 24,
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 24,
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: 16,
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontFamily: "Poppins",
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: "Poppins",
      fontSize: 14,
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontWeight: "semibold",
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: 14,
      letterSpacing: 0.25,
    },
    caption: {
      fontFamily: "Poppins",
      fontSize: 14,
    },
    overline: {
      fontFamily: "Poppins",
      fontSize: 10,
      letterSpacing: 1.5,
    },
  },
});

export default theme;
