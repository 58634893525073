import * as allColors from "@radix-ui/colors";

const defaultTheme = {
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: [
    "1",
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "16px",
    "18px",
    "24px",
    "28px",
  ],
  fontSizes: [8, 10, 14, 16, 18, 25, 31, 39, 48],

  radii: ["0px", "4px", "16px", "24px"],
  space: [0, 4, 8, 12, 16, 24, 32, 44],
  shadows: {
    regular: "0 2px 10px hsla(0, 0%, 0%, 0.141)",
    medium: "3px 3px 3px rgba(0, 0, 0, 0.3)",
  },
};

let currentTheme;

const buildAliases = (color) => ({
  bgBase: color[0],
  bgSubtle: color[1],
  element: color[2],
  elementHover: color[3],
  elementActive: color[4],
  borderSubtle: color[5],
  border: color[6],
  borderHover: color[7],
  accent: color[8],
  accentHover: color[9],
  textLow: color[10],
  textHigh: color[11],
});

const grayScaleMap = {
  mauve: ["tomato", "red", "crimson", "pink", "plum", "purple", "violet"],
  slate: ["indigo", "blue", "sky", "cyan"],
  sage: ["teal", "mint", "green"],
  olive: ["grass", "lime"],
  sand: ["yellow", "amber", "orange", "brown"],
};

const findGrayScale = (accent) => {
  const color = Object.entries(grayScaleMap).find((entry) => {
    const [...colors] = entry[1];
    if (colors.includes(accent)) {
      return true;
    }
    return false;
  });
  return color[0];
};

export const buildTheme = (accentColor, dark = false) => {
  const colorLight = Object.values(allColors[accentColor]);
  const colorDark = Object.values(allColors[`${accentColor}Dark`]);

  const mainColor = dark ? colorDark : colorLight;
  const oppositeColor = dark ? colorLight : colorDark;

  const grayScaleLabel = `${findGrayScale(accentColor)}`;
  const colorGray = Object.values(allColors[grayScaleLabel]);

  const main = buildAliases(mainColor);
  const opposite = buildAliases(oppositeColor);

  // Get Danger colors from "red"
  const dangerColor = Object.values(allColors[dark ? "redDark" : "red"]);
  const danger = buildAliases(dangerColor);

  // Get Danger colors from "orange"
  const warningColor = Object.values(allColors[dark ? "orangeDark" : "orange"]);
  const warning = buildAliases(warningColor);

  // Get Danger colors from "red"
  const successColor = Object.values(allColors[dark ? "greenDark" : "green"]);
  const success = buildAliases(successColor);

  const text = {
    main: { low: main.textLow, mid: main.textHigh, high: main.textHigh },
    accent: {
      dim: main.textLow,
      low: colorGray[6],
      mid: colorGray[3],
      high: colorGray[0],
    },
    opposite: {
      low: main.elementActive,
      mid: main.element,
      high: main.bgSubtle,
    },
  };

  currentTheme = {
    ...defaultTheme,
    colors: {
      ...main,
      opposite,
      text,
      danger: { ...danger },
      warning: { ...warning },
      success: { ...success },
      mvl: {
        primary: "#291F6C",
        primaryVariant: "#29299B",
        secondary: "#50BCBA",
        secondaryVariant: "#0B776F",
        bg: "#F0F0F0",
        text: "#F0F0F0",
      },
    },
  };
  return currentTheme;
};

export const getCurrentTheme = () => currentTheme;
