import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteUser, submitUser, SubmitUserType } from "../../api/user";
import DeleteConfirmationDialog from "../../common/DeleteConfirmationDialog";
import Select from "../../common/Select";
import { Location, User } from "../../entities";
import { getCreateUserValidationSchema, NUMBER_REGEX } from "../../validation";
import "./style.scss";
import ComponentsSelector from "./__containers/ComponentsSelector";
import HallnewsComponent from "../../entities/HallnewsComponent";
import LocationsSelector from "./__containers/LocationsSelector";
import { Col, Row } from "../../styles/layout";
import { useSession } from "../../hooks/SessionProvider";
import { Button } from "../ui/button";
import { toast } from "sonner";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import Dropdown from "../Dropdown";

interface FormikValues extends SubmitUserType {}

interface OwnProps {
  user?: User.Type;
  open: boolean;
  onClose: (shouldRefetch?: boolean) => void;
}

const UserDialog = ({ user, open, onClose }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  const { user: selfUser } = useSession();
  const [availableTypes, setAvailableTypes] = useState<User.UserType[]>([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (open) {
      axios
        .get<User.UserType[]>("/v2/users/available-roles")
        .then((response) => {
          setAvailableTypes(response.data);
        });
    }
  }, [open]);

  const initialFormikValues = useMemo<FormikValues>(
    () => ({
      firstName: "",
      lastName: "",
      middleName: "",
      badgeId: undefined,
      email: "",
      phone: "",
      userConfiguration: {
        language: "fr",
        lockerEmailNotificationsEnabled: true,
        lockerAppNotificationsEnabled: true,
        smsLockerNotificationsEnabled: false,
        emailNotificationsEnabled: true,
        appNotificationsEnabled: true,
        smsNotificationsEnabled: false,
      },
      isHandicap: false,
      isEnabled: true,
      locations: [],
      locationACLs: [],
      ...user,
    }),
    [user]
  );

  const {
    isSubmitting,
    values,
    errors,
    touched,
    dirty,
    setSubmitting,
    setFieldValue,
    setFieldTouched,
    submitForm,
    handleChange,
  } = useFormik<FormikValues>({
    initialValues: initialFormikValues,
    validationSchema: getCreateUserValidationSchema(
      getTranslationByLabel("required-field"),
      getTranslationByLabel("invalid-email"),
      getTranslationByLabel("invalid-phone"),
      getTranslationByLabel("long-string"),
      getTranslationByLabel("long-bange-id")
    ),
    onSubmit: (
      values: FormikValues,
      { setSubmitting }: FormikHelpers<FormikValues>
    ) => {
      setSubmitting(true);
      const item: SubmitUserType = {
        ...values,
        userConfiguration: {
          ...values.userConfiguration,
          lockerAppNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.lockerAppNotificationsEnabled,
          lockerEmailNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.lockerEmailNotificationsEnabled,
          smsLockerNotificationsEnabled:
            isLockerFeatureEnabled &&
            values.userConfiguration?.smsLockerNotificationsEnabled,
          appNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.appNotificationsEnabled,
          smsNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.smsNotificationsEnabled,
          emailNotificationsEnabled:
            isNotificationFeatureEnabled &&
            values.userConfiguration?.emailNotificationsEnabled,
        },
      };

      submitUser(item, !user, selfUser?.role === "MARVELOCK_ADMIN")
        .then(() => {
          setSubmitting(false);
          onClose(true);
        })
        .catch((error) => {
          toast.error(error.response.data);
          setSubmitting(false);
          console.log("Error while save user -> ", error);
        });
    },
  });

  const handleDelete = useCallback(async () => {
    if (!user?.id) {
      return;
    }
    setSubmitting(true);
    try {
      await deleteUser(user.id);
    } catch (error: any) {
      toast.error(error.response.data);
    }
    setSubmitting(false);
    setDeleteDialogOpen(false);
    onClose(true);
  }, [user, setSubmitting, onClose]);

  const languageDisplayedValue = useMemo(() => {
    if (values.userConfiguration) {
      if (values.userConfiguration.language === "en") {
        return getTranslationByLabel("english");
      }
      return getTranslationByLabel("french");
    }
    return "";
  }, [getTranslationByLabel, values.userConfiguration]);

  const isLockerFeatureEnabled = useMemo(() => {
    return values.locationACLs.some(
      (el) => el.location.locationFeature?.lockerFeatureEnabled
    );
  }, [values]);

  const isNotificationFeatureEnabled = useMemo(() => {
    return values.locationACLs.some(
      (el) => el.location.locationFeature?.notificationFeatureEnabled
    );
  }, [values]);

  const isSMSFeatureEnabled = useMemo(() => {
    return values.locationACLs.some(
      (el) => el.location.locationFeature?.smsFeatureEnabled
    );
  }, [values]);

  const locationsFeatures = useMemo(
    () => ({
      locker: isLockerFeatureEnabled,
      notifications: isNotificationFeatureEnabled,
      sms: isSMSFeatureEnabled,
    }),
    [isLockerFeatureEnabled, isNotificationFeatureEnabled, isSMSFeatureEnabled]
  );

  useEffect(() => {
    if (!isLockerFeatureEnabled) {
      setFieldValue("userConfiguration.lockerAppNotificationsEnabled", false);
      setFieldValue("userConfiguration.lockerEmailNotificationsEnabled", false);
      setFieldValue("userConfiguration.smsLockerNotificationsEnabled", false);
    } else if (!user) {
      setFieldValue("userConfiguration.lockerAppNotificationsEnabled", true);
      setFieldValue("userConfiguration.lockerEmailNotificationsEnabled", true);
    } else if (user) {
      setFieldValue(
        "userConfiguration.lockerAppNotificationsEnabled",
        user.userConfiguration?.lockerAppNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.lockerEmailNotificationsEnabled",
        user.userConfiguration?.lockerEmailNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.smsLockerNotificationsEnabled",
        user.userConfiguration?.smsLockerNotificationsEnabled
      );
    }
  }, [user, isLockerFeatureEnabled, setFieldValue]);

  useEffect(() => {
    if (!isNotificationFeatureEnabled) {
      setFieldValue("userConfiguration.appNotificationsEnabled", false);
      setFieldValue("userConfiguration.smsNotificationsEnabled", false);
      setFieldValue("userConfiguration.emailNotificationsEnabled", false);
    } else if (!user) {
      setFieldValue("userConfiguration.appNotificationsEnabled", true);
      setFieldValue("userConfiguration.emailNotificationsEnabled", true);
    } else if (user) {
      setFieldValue(
        "userConfiguration.appNotificationsEnabled",
        user.userConfiguration?.appNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.smsNotificationsEnabled",
        user.userConfiguration?.smsNotificationsEnabled
      );
      setFieldValue(
        "userConfiguration.emailNotificationsEnabled",
        user.userConfiguration?.emailNotificationsEnabled
      );
    }
  }, [user, isNotificationFeatureEnabled, setFieldValue]);

  const showComponentsSelector = useMemo(
    () => !!values.type && values.type !== "RESIDENT",
    [values.type]
  );

  return (
    <>
      <Dialog open={open} onOpenChange={() => onClose(false)}>
        <DialogContent className="w-8/12 max-w-none">
          <DialogHeader>
            <DialogTitle>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h4">
                  {getTranslationByLabel(
                    user?.id ? "update-user" : "create-user"
                  )}
                </Typography>
              </Box>
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col items-stretch">
            <Typography
              textAlign="left"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {getTranslationByLabel("personal-details")}
            </Typography>
            <Box display="flex">
              <InputItem
                className="large-input"
                label={getTranslationByLabel("first-name")}
              >
                <TextField
                  fullWidth
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder={`${getTranslationByLabel(
                    "enter-first-name"
                  )}...`}
                  value={values.firstName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={(touched.firstName && errors.firstName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("middle-name")}
              >
                <TextField
                  fullWidth
                  id="middleName"
                  name="middleName"
                  type="text"
                  placeholder={`${getTranslationByLabel(
                    "enter-middle-name"
                  )}...`}
                  value={values.middleName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.middleName && Boolean(errors.middleName)}
                  helperText={(touched.middleName && errors.middleName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("last-name")}
              >
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder={`${getTranslationByLabel("enter-last-name")}...`}
                  value={values.lastName}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={(touched.lastName && errors.lastName) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("role")}
              >
                {!!user?.id && selfUser?.role !== "MARVELOCK_ADMIN" ? (
                  <Box display="flex" alignItems="center" height={"56px"}>
                    <Typography>
                      {values.type ? getTranslationByLabel(values.type) : "N/A"}
                    </Typography>
                  </Box>
                ) : (
                  <FormControl error={!!errors.type && touched.type}>
                    <Dropdown
                      disableClearing
                      triggerClassList="h-14"
                      placeholder={`${getTranslationByLabel("select-role")}...`}
                      items={availableTypes.map((type) => ({
                        label: getTranslationByLabel(type),
                        value: type,
                      }))}
                      value={values.type ? values.type : ""}
                      onChange={(t) => {
                        setFieldTouched("type", true);
                        setFieldValue("type", t);
                      }}
                    />

                    {Boolean(errors.type) && touched.type && (
                      <FormHelperText>{errors.type}</FormHelperText>
                    )}
                  </FormControl>
                )}
              </InputItem>
            </Box>
            <Typography
              textAlign="left"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {getTranslationByLabel("contact-information")}
            </Typography>
            <Box display="flex">
              <InputItem
                className="large-input"
                label={getTranslationByLabel("email")}
              >
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  placeholder={`${getTranslationByLabel("enter-email")}...`}
                  value={values.email}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.email && Boolean(errors.email)}
                  helperText={(touched.email && errors.email) || " "}
                />
              </InputItem>
              <InputItem
                className="large-input"
                label={getTranslationByLabel("phone-number")}
              >
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  value={values.phone}
                  placeholder={`${getTranslationByLabel(
                    "enter-phone-number"
                  )}...`}
                  onChange={(e) => {
                    if (e.target.value.length > 15) {
                      return;
                    }
                    if (!e.target.value) {
                      e.target.value = "+";
                    }
                    if (e.target.value.length === 1 && e.target.value !== "+") {
                      e.target.value = `+${e.target.value}`;
                    }
                    if (
                      NUMBER_REGEX.test(
                        e.target.value.substring(1, e.target.value.length)
                      ) ||
                      e.target.value === "+"
                    ) {
                      setFieldTouched(e.target.name, true);
                      handleChange(e);
                    }
                  }}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={(touched.phone && errors.phone) || " "}
                />
              </InputItem>
              <InputItem
                className="small-input"
                label={getTranslationByLabel("language")}
              >
                <FormControl error={Boolean(errors.userConfiguration)}>
                  <Dropdown
                    disableClearing
                    triggerClassList="h-14"
                    placeholder={`${getTranslationByLabel("select-role")}...`}
                    items={["fr", "en"].map((lg) => ({
                      label:
                        lg === "en"
                          ? getTranslationByLabel("english")
                          : getTranslationByLabel("french"),
                      value: lg,
                    }))}
                    value={values.userConfiguration?.language}
                    onChange={(t) => {
                      setFieldTouched("userConfiguration.language", true);
                      setFieldValue("userConfiguration.language", t);
                    }}
                  />

                  {Boolean(errors.userConfiguration) && (
                    <FormHelperText>{errors.userConfiguration}</FormHelperText>
                  )}
                </FormControl>
              </InputItem>
              <InputItem
                className="small-input"
                label={getTranslationByLabel("badge-id")}
              >
                <TextField
                  fullWidth
                  id="badgeId"
                  name="badgeId"
                  type="text"
                  placeholder={`${getTranslationByLabel("enter-badge-id")}...`}
                  value={values.badgeId ?? ""}
                  onChange={(e) => {
                    setFieldTouched(e.target.name, true);
                    handleChange(e);
                  }}
                  error={touched.badgeId && Boolean(errors.badgeId)}
                  helperText={(touched.badgeId && errors.badgeId) || " "}
                />
              </InputItem>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isEnabled"
                    checked={values.isEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-user-profile")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="isHandicap"
                    checked={values.isHandicap}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("is-user-handicap")}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!locationsFeatures.locker}
                    name="userConfiguration.lockerEmailNotificationsEnabled"
                    checked={
                      values.userConfiguration?.lockerEmailNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel(
                  "enable-locker-email-notifications"
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      !locationsFeatures.locker ||
                      !locationsFeatures.notifications
                    }
                    name="userConfiguration.lockerAppNotificationsEnabled"
                    checked={
                      values.userConfiguration?.lockerAppNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-locker-app-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={
                      !locationsFeatures.locker || !locationsFeatures.sms
                    }
                    name="userConfiguration.smsLockerNotificationsEnabled"
                    checked={
                      values.userConfiguration?.smsLockerNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-locker-sms-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!locationsFeatures.notifications}
                    name="userConfiguration.emailNotificationsEnabled"
                    checked={
                      values.userConfiguration?.emailNotificationsEnabled
                    }
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-email-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!locationsFeatures.notifications}
                    name="userConfiguration.appNotificationsEnabled"
                    checked={values.userConfiguration?.appNotificationsEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-app-notifications")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!locationsFeatures.sms}
                    name="userConfiguration.smsNotificationsEnabled"
                    checked={values.userConfiguration?.smsNotificationsEnabled}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      });
                    }}
                  />
                }
                label={getTranslationByLabel("enable-sms-notifications")}
              />
            </Box>
            <Row width="100%" mt="16px">
              <Col alignItems="stretch" flexBasis={"50%"} mr="5">
                <Typography
                  textAlign="left"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  {getTranslationByLabel("locations-menu")}
                </Typography>
                <LocationsSelector
                  selectedLocations={
                    values.locationACLs?.map((a) => a.location) ?? []
                  }
                  onChange={(locs: Location.Type[]) => {
                    setFieldValue(
                      "locationACLs",
                      locs.map((c) => ({
                        location: c,
                        permission: "ADMIN",
                      }))
                    );

                    setFieldValue(
                      "componentACLs",
                      values.componentACLs?.filter((a) =>
                        locs.find(
                          (l) =>
                            a.component.organization.id === l.organization.id
                        )
                      )
                    );
                  }}
                />
              </Col>
              {showComponentsSelector && (
                <Col alignItems="stretch" flexBasis={"50%"}>
                  {values.locationACLs.length > 0 && (
                    <>
                      <Typography
                        textAlign="left"
                        variant="h6"
                        color="primary"
                        gutterBottom
                      >
                        Widgets
                      </Typography>
                      <ComponentsSelector
                        organisationIds={values.locationACLs.map(
                          (a) => a.location.organization.id
                        )}
                        selectedComponents={
                          values.componentACLs?.map((a) => a.component) ?? []
                        }
                        onChange={(comps: HallnewsComponent.Type[]) => {
                          setFieldValue(
                            "componentACLs",
                            comps.map((c) => ({
                              component: c,
                              permission: "ADMIN",
                            }))
                          );
                        }}
                      />
                    </>
                  )}
                </Col>
              )}
            </Row>
          </div>
          <DialogActions className="actions">
            {!!user?.id && selfUser && selfUser.role !== "RESIDENT" && (
              <>
                <Button
                  variant="destructive"
                  disabled={isSubmitting}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  {getTranslationByLabel("delete")}
                </Button>
              </>
            )}
            <Button disabled={isSubmitting || !dirty} onClick={submitForm}>
              {getTranslationByLabel("submit")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <DeleteConfirmationDialog
        open={deleteDialogOpen}
        // disabled={isSubmitting}
        onConfirm={handleDelete}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
      />
    </>
  );
};

interface InputItemProps {
  label: string;
  className?: string;
}

const InputItem: React.FC<InputItemProps> = ({
  children,
  label,
  className,
}) => {
  return (
    <Box display="flex" flexDirection="column" mr={2} className={className}>
      <Typography
        component="p"
        textAlign="left"
        textTransform="uppercase"
        variant="caption"
        color="primary"
      >
        {label}
      </Typography>
      {children}
    </Box>
  );
};

export default UserDialog;
