import { useMemo } from "react";
import WithLoadingBackdrop from "../../../common/WithLoadingBackdrop";

import { Col, Row } from "../../../styles/layout";
import { Text } from "../../../styles/typography";

import { useComponents } from "../../../hooks/useComponents";

import HallnewsComponentContent from "../../../entities/HallnewsComponentContent";
import { sortBy } from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { formatDate } from "date-fns";
import { CardDescription, CardTitle } from "../../../components/ui/card";

const ResidentHome = ({ match, ...props }: any) => {
  const { t: getTranslationByLabel } = useTranslation();

  const { components, isLoading: componentsLoading } = useComponents(0, 1000);

  return (
    <div className="space-y-6">
      <div>
        <CardTitle className="text-2xl">Notifications</CardTitle>
        <CardDescription>Retrouvez toutes vos notifications.</CardDescription>
      </div>
      <Row alignItems="stretch" height="100%">
        <div className="flex flex-col items-stretch">
          <Row alignItems={"flex-start"} maxHeight="100%" minHeight="0">
            {components
              .filter(
                (c) => (c.content?.filter(isContentActive).length ?? 0) > 0
              )
              .map((comp: any) => (
                <div
                  key={comp.name}
                  className="flex flex-col items-stretch space-y-2"
                >
                  <div className="text-foreground text-lg font-semibold">
                    {comp.label}
                  </div>
                  <div className="overflow-scroll space-y-2">
                    {sortBy(comp.content, "validFrom", "DESC")
                      .filter(isContentActive)
                      .map((cont: any) => (
                        <ContentCard content={cont} key={cont.id} />
                      ))}
                  </div>
                </div>
              ))}
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default ResidentHome;

const isContentActive = (content: HallnewsComponentContent.Type) => {
  const now = new Date();
  const validFrom = new Date(content.validFrom);
  const validTo = new Date(content.validTo);
  if (now > validTo) {
    return false;
  }
  if (now < validFrom) {
    return false;
  }
  return true;
};

const ContentCard = ({
  content,
}: {
  content: HallnewsComponentContent.Type;
}) => {
  const contentPayload = useMemo(() => {
    const data = JSON.parse(content.payload);
    console.log(data);
    return data;
  }, [content]);

  const contentStatus = useMemo(() => {
    const now = new Date();
    const validFrom = new Date(content.validFrom);
    const validTo = new Date(content.validTo);
    if (now > validTo) {
      return "expired";
    }
    if (now < validFrom) {
      return "scheduled";
    }
    return "active";
  }, [content]);

  return (
    <div className="rounded shadow-sm flex items-center relative flex-grow border min-w-96">
      <Col p="3">
        <Text color="textHigh" fontSize="18px" fontWeight="600" mb="1">
          {contentPayload?.title}
        </Text>
        <Text
          color="textHigh"
          maxWidth="75ch"
          fontWeight="300"
          fontSize="16px"
          mb="2"
        >
          {contentPayload?.content}
        </Text>
        {contentStatus === "active" && (
          <div className="text-xs text-muted-foreground ">
            {formatDate(new Date(content.validTo), "dd/MM/yyyy")}
          </div>
        )}
      </Col>
    </div>
  );
};
