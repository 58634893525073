import axios from "axios";
import { PartialApiItemsResponse } from ".";
import { Location } from "../entities";
import { SortDirection } from "../filters";
import { IGetLocationParams } from "../store/locationsSlice";
import Organisation from "../entities/Organisation";

export interface LocationResponse extends PartialApiItemsResponse {
  content: Location.Type[];
}

export interface LocationFilters {
  locationNames: string[];
  companyNames: string[];
  buildingNames: string[];
}

export const fetchLocations = (
  page: number,
  items: number,
  filters?: IGetLocationParams["filters"]
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("page", String(page));
  urlSearchParams.append("items", String(items));
  urlSearchParams.append("sortDirection", filters?.sortDirection ?? "ASC");
  if (filters) {
    const { companyName, buildingName, locationName } = filters;
    if (companyName) {
      urlSearchParams.append("company", companyName);
    }
    if (buildingName) {
      urlSearchParams.append("building", buildingName);
    }
    if (locationName) {
      urlSearchParams.append("location-name", locationName);
    }
  }

  return new Promise<LocationResponse>((resolve, reject) => {
    axios
      .get(`/v2/locations?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export interface SubmitLocationType
  extends Partial<Omit<Location.Type, "locationFeature" | "organization">> {
  locationFeature: Partial<Location.Feature>;
  organization: Organisation.Type;
}

export const submitLocation = (item: SubmitLocationType, isNew?: boolean) => {
  if (isNew) {
    return axios.post("/v2/locations", {
      ...item,
    });
  } else {
    return axios.put(`/v2/locations/${item.id}`, {
      ...item,
    });
  }
};

export const deleteLocation = (id: string) => {
  return axios.delete(`/v1/locations/${id}`);
};

export const fetchLocationFilters = (
  companyName?: string,
  locationName?: string,
  buildingName?: string,
  sortDirection: SortDirection = "ASC"
) => {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append("sortDirection", sortDirection);
  if (companyName) {
    urlSearchParams.append("company-name", companyName);
  }
  if (locationName) {
    urlSearchParams.append("location-name", locationName);
  }
  if (buildingName) {
    urlSearchParams.append("building-name", buildingName);
  }
  return new Promise<LocationFilters>((resolve, reject) => {
    axios
      .get(`/v1/location/filters?${urlSearchParams.toString()}`)
      .then((response) => {
        resolve(response.data ?? {});
      })
      .catch((error) => {
        reject(error);
      });
  });
};
