import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from ".";
import {
  fetchSystemNotifications,
  SystemNotificationResponse,
} from "../api/systemNotifications";
import { SystemNotification } from "../entities";
import { EnumNotificationType, showNotification } from "./notificationSlice";

export interface StateItems {
  [id: string]: SystemNotification.Type;
}

export interface SystemNotificationsState {
  items: StateItems;
  loading: boolean;
  pages: number;
  elements: number;
  error?: string;
}

const initialState: SystemNotificationsState = {
  items: {},
  pages: 0,
  elements: 0,
  loading: false,
};

interface IGetSystemNotifications
  extends Omit<SystemNotificationResponse, "content"> {
  items: StateItems;
}

export const getSystemNotifications = createAsyncThunk<
  IGetSystemNotifications,
  { page: number; filter?: SystemNotification.State },
  {
    state: RootState;
  }
>(
  "systemNotifications/getSystemNotifications",
  async ({ page, filter }, { dispatch, rejectWithValue, getState }) => {
    try {
      const selfUser = getState().user.self;
      dispatch(setFetchSystemNotificationsError());
      dispatch(setLoading(true));
      const reponse: SystemNotificationResponse =
        await fetchSystemNotifications(
          page,
          10,
          selfUser?.role === "RESIDENT",
          filter
        );
      const content: SystemNotification.Type[] = reponse.content
        .map((el) => {
          return {
            ...el,
            effectiveDate: moment(el.effectiveDate).utc(),
            termDate: moment(el.termDate).utc(),
          };
        })
        .sort((el1, el2) => {
          if (
            (el1.archived && el2.archived) ||
            (!el1.archived && !el2.archived)
          ) {
            return 0;
          }
          if (el2.archived) {
            return -1;
          }
          return 1;
        });
      const items: StateItems = {};
      content.forEach((item) => {
        items[item.id] = item;
      });
      return {
        ...reponse,
        items,
      };
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  }
);

export const systemNotificationsSlice = createSlice({
  name: "systemNotifications",
  initialState,
  reducers: {
    setSystemNotifications: (state, action: PayloadAction<StateItems>) => {
      state.items = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchSystemNotificationsError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSystemNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSystemNotifications.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.pages = action.payload.totalPages;
      state.elements = action.payload.totalElements;
      state.loading = false;
    });
    builder.addCase(getSystemNotifications.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const {
  setSystemNotifications,
  setFetchSystemNotificationsError,
  setLoading,
} = systemNotificationsSlice.actions;
export const selectItems = (state: RootState) => {
  return Object.keys(state.systemNotifications.items).map(
    (key) => state.systemNotifications.items[key]
  );
};
export const selectIsLoading = (state: RootState) =>
  state.systemNotifications.loading;
export const selectPages = (state: RootState) =>
  state.systemNotifications.pages;
export const selectItemsCount = (state: RootState) =>
  state.systemNotifications.elements;
export const selectFetchItemsError = (state: RootState) =>
  state.systemNotifications.error;

export default systemNotificationsSlice.reducer;
