import Layout from "../entities/Layout";
import { apiFetch } from "../lib/apiFetch";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  findByLocationId: `${API}/hallnews/layout`,
  listLayouts: `${API}/hallnews/layout/list`,
};

export const create = (body: Layout.Type) =>
  apiFetch({
    method: "POST",
    url: `${API}/hallnews/layout`,
    body,
    authRequired: true,
  });

export const update = (body: Layout.Type) =>
  apiFetch({
    method: "POST",
    url: `${API}/hallnews/layout/${body.id}`,
    body,
    authRequired: true,
  });
