import { IconType } from "react-icons";
import clsx from "clsx";
import { useMemo } from "react";

import {
  LucideAlertTriangle,
  LucideBuilding,
  LucideBuilding2,
  LucideIcon,
  LucideLock,
  LucideSettings,
  LucideTable2,
  LucideTv2,
  LucideUsers2,
} from "lucide-react";
import { useRouter } from "../hooks/useRouter";
import { useTranslation } from "react-i18next";

import { useState } from "react";
import {
  LOCATIONS_PATH,
  LOCKERS_PATH,
  HALLNEWS_PATH,
  REPORTS_PATH,
  USERS_PATH,
  ORGANISATIONS_PATH,
  SETTINGS_PATH,
} from "../routes";
import { cn } from "../lib/utils";
import { useUserAccess } from "../hooks/useUserAccess";

const NavItem = ({
  label,
  path,
  Icon,
}: {
  label: string;
  path: string;
  Icon: IconType | LucideIcon;
}) => {
  const { t: getTranslationByLabel } = useTranslation();

  const router = useRouter();
  const isActive = useMemo(() => {
    if (path === "/") {
      return path === router.pathname;
    } else {
      return router.pathname.includes(path);
    }
  }, [router.pathname, path]);

  return (
    <div
      className={clsx(
        "flex cursor-pointer items-center justify-start space-x-3 py-3 font-medium hover:font-bold",
        isActive ? "text-primary" : "text-white"
      )}
      onClick={() => router.push(path)}
    >
      <div
        className={cn({
          "text-secondary": isActive,
          "text-white": !isActive,
        })}
      >
        <Icon size={20} />
      </div>
      <div
        className={cn({
          "text-secondary font-semibold": isActive,
          "text-white": !isActive,
        })}
      >
        {getTranslationByLabel(label)}
      </div>
    </div>
  );
};

interface Item {
  label: string;
  path: string;
  icon: LucideIcon;
}

const Nav = () => {
  const userAccess = useUserAccess();

  const ITEMS = useMemo(() => {
    if (!userAccess) return [];
    const {
      hasNotificationsAccess,
      hasServiceOrdersAccess,
      hasLockersAccess,
      hasUsersAccess,
      hasLocationsAccess,
      hasReportsAccess,
      hasOrganisationsAccess,
    } = userAccess;

    const result: Item[] = [];
    if (hasOrganisationsAccess) {
      result.push({
        label: "organisations-menu",
        path: ORGANISATIONS_PATH,
        icon: LucideBuilding2,
      });
    }
    if (hasLocationsAccess) {
      result.push({
        label: "locations-menu",
        path: LOCATIONS_PATH,
        icon: LucideBuilding,
      });
    }
    if (hasUsersAccess) {
      result.push({
        label: "users-menu",
        path: USERS_PATH,
        icon: LucideUsers2,
      });
    }
    if (hasNotificationsAccess) {
      result.push({
        label: "hallnews-menu",
        path: HALLNEWS_PATH,
        icon: LucideTv2,
      });
    }

    if (hasLockersAccess) {
      result.push({
        label: "lockers-menu",
        path: LOCKERS_PATH,
        icon: LucideLock,
      });
    }

    if (hasServiceOrdersAccess || hasReportsAccess) {
      result.push({
        label: "reports-menu",
        path: REPORTS_PATH,
        icon: LucideTable2,
      });
    }

    result.push({
      label: "settings-menu",
      path: SETTINGS_PATH,
      icon: LucideSettings,
    });

    return result;
  }, [userAccess]);

  return (
    <div className="flex  min-w-[240px] flex-col space-y-8 bg-primary text-center py-8">
      <a
        href="/"
        target="_blank"
        rel="noreferrer"
        className="w-full flex justify-center items-center"
      >
        <img
          src={require("../assets/full-logo-dark.png")}
          style={{ objectFit: "contain", height: 32, width: "auto" }}
          alt="Marvelock logo"
        />
      </a>
      <div className="flex flex-col px-4 flex-grow">
        {ITEMS.map(({ label, path, icon }) => (
          <NavItem key={path} label={label} path={path} Icon={icon} />
        ))}
      </div>
      {process.env.REACT_APP_STAGE !== "prod" && (
        <div className="text-destructive text-center p-6 flex flex-col items-center">
          <LucideAlertTriangle size={32} className="text-destructive" />
          <span>{process.env.REACT_APP_STAGE}</span>
        </div>
      )}
    </div>
  );
};

export default Nav;
