import { withRouter } from "react-router-dom";

import { useMemo, useState } from "react";
import { useComponent } from "../../hooks/useComponent";
import HallnewsComponentContent from "../../entities/HallnewsComponentContent";
import UpdateComponentDialog from "./__containers/UpdateComponentDialog";
import { useRouter } from "../../hooks/useRouter";
import { useUser } from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/ui/button";
import { CardDescription, CardTitle } from "../../components/ui/card";
import { format, formatDate } from "date-fns";
import { Badge } from "../../components/ui/badge";
import { cn } from "../../lib/utils";
import ContentDialog from "./__containers/ContentDialog";
import { usePagination } from "../../hooks/usePagination";
import { Pagination } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useComponentContent } from "../../hooks/useComponentContent";

const HallnewsWidget = ({ match }: any) => {
  const router = useRouter();
  const routerLocation = useLocation();

  const { t: getTranslationByLabel } = useTranslation();

  const componentId = useMemo(() => match.params.componentId, [match.params]);
  const { isAuthorized } = useUser();
  const { component, refresh: refreshComponent } = useComponent(componentId);
  const [page, setPage] = usePagination();
  const {
    content,
    pages,
    refresh: refreshContent,
  } = useComponentContent(componentId, {
    page: (page ?? 1) - 1,
    items: 10,
    sort: "createdAt",
    sortDirection: "DESC",
  });

  const [showUpdateComponent, setShowUpdateComponent] =
    useState<boolean>(false);

  const [showContentDialog, setShowContentDialog] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<
    HallnewsComponentContent.Type | undefined
  >();

  return (
    <>
      {component && (
        <div className="space-y-6">
          <div className="space-y-0">
            <CardTitle className="font-semibold text-lg flex items-end justify-between">
              <div>
                <div className="text-muted-foreground text-sm font-semibold">
                  {getTranslationByLabel("hallnews-layout")}
                </div>
                <div className="text-xl font-semibold">{component.label}</div>
              </div>
              <div className="flex items-center space-x-4">
                <Pagination
                  onChange={(_, page) => {
                    setPage(page, routerLocation.search);
                  }}
                  page={page ?? 1}
                  count={pages}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                />
                {
                  <div className="space-x-2">
                    {isAuthorized("SITE_MANAGER") && (
                      <Button
                        variant="outline"
                        onClick={() => setShowUpdateComponent(true)}
                      >
                        {getTranslationByLabel("update")}
                      </Button>
                    )}
                    {isAuthorized("BUILDING_MANAGER") && (
                      <Button
                        variant="default"
                        onClick={() => setShowContentDialog(true)}
                      >
                        {getTranslationByLabel("add")}
                      </Button>
                    )}
                  </div>
                }
                {}
              </div>
            </CardTitle>
            <CardDescription>
              {getTranslationByLabel("hallnews-component-description")}
            </CardDescription>
          </div>

          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b border-border font-medium">
              <tr className="pb-4 text-muted-foreground">
                {/* <th className="py-4 w-48">Date</th> */}
                <th className="py-4 w-48">Status</th>
                <th className="py-4 w-48">Title</th>
                <th className="py-4 w-80">Message</th>
                <th className="py-4">Début</th>
                <th className="py-4">Fin</th>
                <th className="py-4"></th>
              </tr>
            </thead>
            <tbody>
              {content?.map((content) => {
                const { title, content: message } = JSON.parse(content.payload);
                const contentStatus = () => {
                  const now = new Date();
                  const validFrom = new Date(content.validFrom);
                  const validTo = new Date(content.validTo);
                  console.log(validTo);
                  if (now > validTo) {
                    return getTranslationByLabel("notification-status-expired");
                  }
                  if (now < validFrom) {
                    return getTranslationByLabel(
                      "notification-status-scheduled"
                    );
                  }
                  return getTranslationByLabel("notification-status-active");
                };
                const status = contentStatus();

                return (
                  <tr
                    key={content.id}
                    className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
                  >
                    {/* <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                      {formatDate(
                        new Date(content.createdAt ?? ""),
                        "dd/MM/yyyy"
                      )}
                    </td> */}
                    <td>
                      <Badge
                        className={cn({
                          "bg-green-500 text-white":
                            status ===
                            getTranslationByLabel("notification-status-active"),
                          "bg-red-500 text-white":
                            status ===
                            getTranslationByLabel(
                              "notification-status-expired"
                            ),
                        })}
                      >
                        {status}
                      </Badge>
                    </td>
                    <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                      {title}
                    </td>
                    <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light text-ellipsis overflow-hidden max-w-80">
                      {message}
                    </td>

                    <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                      {format(new Date(content.validFrom), "dd MMMM HH:mm")}
                    </td>
                    <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                      {format(new Date(content.validTo), "dd MMMM HH:mm")}
                    </td>
                    <td>
                      <div className="flex justify-end space-x-2">
                        <Button
                          variant="default"
                          size="sm"
                          onClick={() => setSelectedContent(content)}
                        >
                          {getTranslationByLabel("edit")}
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <UpdateComponentDialog
            open={showUpdateComponent}
            onClose={() => {
              setShowUpdateComponent(false);
              refreshComponent();
            }}
            onDeleted={() => router.push("..")}
            component={component}
          />
          <ContentDialog
            open={!!selectedContent || showContentDialog}
            content={selectedContent}
            component={component}
            onClose={() => {
              setSelectedContent(undefined);
              setShowContentDialog(false);
              refreshContent();
            }}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(HallnewsWidget);
