export const PRIMARY = "#291F6C";
export const PRIMARY_VARIANT = "#29299B";
export const SECONDARY = "#50BCBA";
export const SECONDARY_VARIANT = "#0B776F";
export const BACKGROUND = "#F0F0F0";
export const TEXT = "#F0F0F0";
export const TEXT_SECONDARY = "#707070B3";
export const ERROR = "#AF1B3F";
export const ACCENT_COLOR = "#25A09A";
export const ATTENTION_COLOR = "#FCCA46";
export const BUTTON_BORDER_COLOR = "#0000001F";
