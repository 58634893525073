import Locker from "./Locker";

export enum DoorStatus {
  Open = "OPEN",
  Close = "CLOSE",
}
export namespace Bin {
  export type Door = {
    id?: string;
    status: DoorStatus;
    size: number;
  };

  export type Type = {
    id: string;
    number: number;
    binDoor: Door;
    isEmpty: boolean;
    isHandicap: boolean;
    organization: string;
    active: boolean;
    locker: Locker.Type;
  };
}

export const parseBinSizeKey = (size: number) => {
  if (isNaN(size)) {
    return "not-available";
  }
  switch (size) {
    case 1:
      return "small";
    case 2:
      return "medium";
    case 3:
      return "large";
    case 4:
      return "xl";
    case 5:
      return "xxl";
    default:
      return "not-available";
  }
};

export default Bin;
