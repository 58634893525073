import { cn } from "../lib/utils";

interface Props {
  label: string;
  required?: boolean;
  error?: boolean;
  className?: string;
}
export const Label = ({ label, required, error, className }: Props) => {
  return (
    <label
      className={cn(
        "text-foreground line- text-sm font-semibold leading-loose",
        {
          "text-destructive": !!error,
        },
        className
      )}
    >
      {`${label}${required ? "*" : ""}`}
    </label>
  );
};
