import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { fetchLocations, LocationResponse } from "../api/locations";
import { Location } from "../entities";
import { SortDirection } from "../filters";
import { EnumNotificationType, showNotification } from "./notificationSlice";

export interface StateItems {
  [id: string]: Location.Type;
}

export interface LocationsState {
  items: StateItems;
  loading: boolean;
  pages: number;
  elements: number;
  error?: string;
}

const initialState: LocationsState = {
  items: {},
  pages: 0,
  elements: 0,
  loading: false,
};

interface IGetLocations extends Omit<LocationResponse, "content"> {
  items: StateItems;
}

export interface IGetLocationParams {
  page: number;
  itemsPerPage: number;
  filters?: {
    companyName?: string;
    locationName?: string;
    buildingName?: string;
    sortDirection?: SortDirection;
  };
}

export const getLocations = createAsyncThunk<
  IGetLocations,
  IGetLocationParams,
  {
    state: RootState;
  }
>(
  "locations/getLocations",
  async (
    { page, itemsPerPage, filters = {} },
    { dispatch, rejectWithValue }
  ) => {
    try {
      dispatch(setFetchLocationsError());
      dispatch(setLoading(true));
      const response = await fetchLocations(page, itemsPerPage, filters);
      const items: StateItems = {};
      response.content.forEach((item) => {
        items[item.id] = item;
      });
      return {
        ...response,
        items,
      };
    } catch (error: any) {
      console.log("Error -> ", error);
      dispatch(
        showNotification({
          text: "Something went wrong. Please try again later.",
          type: EnumNotificationType.Error,
        })
      );
      return rejectWithValue(error.response?.data);
    }
  }
);

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFetchLocationsError: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLocations.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.pages = action.payload.totalPages;
      state.elements = action.payload.totalElements;
      state.loading = false;
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { setFetchLocationsError, setLoading } = locationsSlice.actions;
export const selectItems = (state: RootState) => {
  return Object.keys(state.locations.items).map(
    (key) => state.locations.items[key]
  );
};
export const selectIsLoading = (state: RootState) => state.locations.loading;
export const selectPages = (state: RootState) => state.locations.pages;
export const selectItemsCount = (state: RootState) => state.locations.elements;
export const selectFetchItemsError = (state: RootState) =>
  state.locations.error;
export const selectItemById = (state: RootState, id?: string) => {
  if (!id) {
    return null;
  }
  return state.locations.items[id];
};
export default locationsSlice.reducer;
