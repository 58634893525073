import { TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/system/Box";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bin } from "../entities";
import { DoorStatus } from "../entities/Bin";
import Dropdown from "./Dropdown";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog";

interface OwnProps {
  open: boolean;
  bin?: Partial<Bin.Type>;
  onClose: () => void;
  onSubmit: (item: Partial<Bin.Type>) => void;
  onRemoveBin: () => void;
}

const AddBinDialog = ({
  open,
  bin,
  onSubmit,
  onRemoveBin,
  onClose,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const [binState, setBinState] = useState<Partial<Bin.Type>>(
    bin ?? {
      active: false,
      isHandicap: false,
    }
  );

  useEffect(() => {
    if (!open) {
      setBinState({
        active: false,
        isHandicap: false,
      });
    }
  }, [open]);

  useEffect(() => {
    if (bin) {
      setBinState(bin);
    }
  }, [bin]);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="w-fit max-w-none">
        <DialogHeader>
          <DialogTitle>
            {getTranslationByLabel(bin ? "update-bin" : "add-new-bin")}
          </DialogTitle>
        </DialogHeader>
        <Box display="flex" flexDirection="column">
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("bin-number")}
          </Typography>
          <TextField
            fullWidth
            placeholder={`${getTranslationByLabel("enter-bin-number")}...`}
            value={binState.number ? String(binState.number) : ""}
            onChange={(event) => {
              if (isNaN(Number(event.target.value))) {
                return;
              }
              setBinState((prevState) => ({
                ...prevState,
                number: Number(event.target.value),
              }));
            }}
          />
          <Typography
            component="p"
            textAlign="left"
            textTransform="uppercase"
            variant="caption"
            color="primary"
          >
            {getTranslationByLabel("bin-size")}
          </Typography>
          <Dropdown
            items={["small", "medium", "large", "xl", "xxl"].map((s, idx) => ({
              label: getTranslationByLabel(s),
              value: (idx + 1).toString(),
            }))}
            value={binState?.binDoor?.size.toString()}
            onChange={(val) => {
              setBinState((prevState) => ({
                ...prevState,
                binDoor: {
                  status: prevState.binDoor
                    ? prevState.binDoor.status
                    : DoorStatus.Close,
                  size: Number(val),
                },
              }));
            }}
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!binState.isHandicap}
                  onChange={(e) => {
                    setBinState((prevState) => ({
                      ...prevState,
                      isHandicap: e.target.checked,
                    }));
                  }}
                />
              }
              label={getTranslationByLabel("bin-handicap-enabled")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!binState.active}
                  onChange={(e) => {
                    setBinState((prevState) => ({
                      ...prevState,
                      active: e.target.checked,
                    }));
                  }}
                />
              }
              label={getTranslationByLabel("bin-active")}
            />
          </FormGroup>
        </Box>
        <DialogFooter>
          {bin && (
            <Button className="button" variant="text" onClick={onRemoveBin}>
              {getTranslationByLabel("remove-bin")}
            </Button>
          )}
          <Button
            variant="contained"
            autoFocus
            disabled={!binState.number || !binState.binDoor}
            onClick={() => {
              onSubmit(binState);
            }}
          >
            {getTranslationByLabel(bin ? "update-bin" : "add-bin")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddBinDialog;
