import { routes } from "../services/locker";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { Locker } from "../entities";

export function useLockerState(lockerId: string, lockerData?: Locker.Type) {
  const { data, error } = useSWR(
    { url: routes.getStateById(lockerId) },
    fetcher<boolean>,
    {
      suspense: false,
      shouldRetryOnError: false,
      refreshInterval: 10000,
      fallbackData: lockerData?.on,
    }
  );

  return {
    state: data,
    isLoading: !error && !data,
    isError: error,
  };
}
