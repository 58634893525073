import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import "./style.scss";

type OwnProps = {
  label: string;
  isSwitchOn: boolean;
  onToggleSwitch: (value: boolean) => void;
  disabled?: boolean;
};

const NotificationSettingsRow = ({
  label,
  isSwitchOn,
  disabled,
  onToggleSwitch,
}: OwnProps) => {
  return (
    <div className="notification-settings-container">
      <Typography
        variant="subtitle1"
        className={classNames("label", { disabled })}
        color="primary"
      >
        {label}
      </Typography>
      <Switch
        color="primary"
        checked={isSwitchOn}
        onChange={(_, checked) => {
          onToggleSwitch(checked);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default NotificationSettingsRow;
