import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconButton from "@mui/material/IconButton";

import { Col, Row } from "../../../styles/layout";
import { Text } from "../../../styles/typography";
import { useMemo } from "react";

import { ClickableCard } from "../../ClickableCard";
import { useLocations } from "../../../hooks/useLocations";
import { Location } from "../../../entities";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Dropdown";
import { useSession } from "../../../hooks/SessionProvider";

interface OwnProps {
  selectedLocations: Location.Type[];
  onChange: (e: Location.Type[]) => void;
}

const LocationsSelector = ({ selectedLocations, onChange }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  const { locations } = useLocations(0, 100);
  const { user: selfUser } = useSession();

  const userLocations =
    selfUser?.locationACLs.map((acl) => acl.location.id) ?? [];

  const availableLocations = useMemo(
    () =>
      locations.filter(
        (comp: any) => !selectedLocations.find((c) => c.id === comp.id)
      ),
    [locations, selectedLocations]
  );

  return (
    <>
      <Col>
        <Dropdown
          items={availableLocations.map((l: Location.Type) => ({
            label: l.name,
            value: l.id,
          }))}
          placeholder={
            availableLocations.length > 0
              ? getTranslationByLabel("user-dialog-locations-prompt")
              : getTranslationByLabel("user-dialog-locations-none")
          }
          onChange={(v: string | undefined) => {
            onChange([
              ...selectedLocations,
              locations.find((o: Location.Type) => o.id === v)!,
            ]);
          }}
          triggerClassList="h-14"
        />
        <Row mt="2" flexWrap="wrap">
          {selectedLocations.map((l) => {
            const isDisabled =
              selfUser?.role !== "BUILDING_MANAGER"
                ? false
                : !userLocations.includes(l.id);
            return (
              <ClickableCard
                key={l.id}
                onClick={() =>
                  onChange(
                    selectedLocations.filter(
                      (c: Location.Type) => c.id !== l.id
                    )
                  )
                }
                disabled={isDisabled}
              >
                <Row
                  p="3"
                  m="1"
                  bg="element"
                  opacity={isDisabled ? 0.5 : 1}
                  borderColor="elementActive"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="1"
                  mr="3"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Text
                    fontSize="16px"
                    fontWeight="600"
                    color="textHigh"
                    mr="4"
                  >
                    {l.name}
                  </Text>
                  {!isDisabled && (
                    <IconButton aria-label="close" color="primary">
                      <FontAwesomeIcon
                        icon={faClose}
                        size="xs"
                        width={14}
                        height={14}
                      />
                    </IconButton>
                  )}
                </Row>
              </ClickableCard>
            );
          })}
        </Row>
      </Col>
    </>
  );
};

export default LocationsSelector;
