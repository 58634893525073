import { apiFetch } from "../lib/apiFetch";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  list: `${API}/v2/users`,
  self: `${API}/v2/users/self`,
};

export const create = (body: any) =>
  apiFetch({
    method: "POST",
    url: `${API}/v2/users`,
    body,
    authRequired: true,
  });

export const update = (body: any) =>
  apiFetch({
    method: "PUT",
    url: `${API}/v2/users/${body.id}`,
    body,
    authRequired: true,
  });

export const deleteUser = (id: string) =>
  apiFetch({
    method: "DELETE",
    url: `${API}/v2/users/${id}`,
    authRequired: true,
  });
