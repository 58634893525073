import { Div } from "../../../styles/layout";

import HallnewsComponentPlacement from "../../../entities/HallnewsComponentPlacement";
import Layout from "../../../entities/Layout";
import { useState } from "react";
import { Location } from "../../../entities";
import { Button } from "../../../components/ui/button";
import { useTranslation } from "react-i18next";
import PlacementConfig from "./PlacementConfig";

const PlacementsGrid = ({
  location,
  placements,
  layout,
  refreshPlacements,
  onAdd,
}: {
  location: Location.Type;
  placements: HallnewsComponentPlacement.Type[];
  layout: Layout.Type;
  refreshPlacements: () => void;
  onAdd: () => void;
}) => {
  const { t: getTranslationByLabel } = useTranslation();

  const [selectedPlacement, setSelectedPlacement] =
    useState<HallnewsComponentPlacement.Type | null>(null);

  return (
    <>
      {layout && (
        <div className="flex space-x-4 w-full">
          <div
            className="relative h-full w-full min-w-[600px] max-w-[800px] aspect-video bg-cover bg-no-repeat  border-4 rounded-md border-primary shadow-sm"
            style={{
              backgroundImage:
                layout.imageUrl || location.image
                  ? `url(${layout.imageUrl || location.image})`
                  : "none",
            }}
          >
            <div className="flex justify-end absolute top-1 right-1">
              <Button onClick={onAdd} variant="outline" size="sm">
                {getTranslationByLabel("add")}
              </Button>
            </div>
            <Div
              height="100%"
              width="100%"
              borderRadius="1"
              display="grid"
              gridTemplateColumns={`repeat(${layout.gridCols}, [col-start] 1fr)`}
              gridTemplateRows={`repeat(${layout.gridRows}, [row-start] 1fr)`}
              gridGap="8px"
            >
              {placements.map((placement: HallnewsComponentPlacement.Type) => (
                <div
                  style={{
                    gridColumn: `col-start ${
                      placement.gridCol + 1
                    } / col-start ${
                      placement.gridCol + 1 + placement.gridColSpan
                    }`,
                    gridRow: `row-start ${placement.gridRow + 1} / row-start ${
                      placement.gridRow + 1 + placement.gridRowSpan
                    }`,
                  }}
                  onClick={() => setSelectedPlacement(placement)}
                >
                  <div className="w-full h-full p-1">
                    <div className="h-full w-full bg-black/60 rounded border border-border flex items-center justify-center text-white cursor-pointer hover:bg-black/70 transition-colors">
                      {placement.component!.label}
                    </div>
                  </div>
                </div>
              ))}
            </Div>
          </div>
          <div className="flex-grow">
            {selectedPlacement && (
              <PlacementConfig
                layout={layout}
                placement={selectedPlacement}
                onSave={refreshPlacements}
                onDelete={refreshPlacements}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PlacementsGrid;
