import { routes } from "../services/layouts";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import Layout from "../entities/Layout";

export function useLayouts() {
  const { data, error, mutate } = useSWR(
    { url: routes.listLayouts, queryParam: {} },
    fetcher<Layout.Type[]>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    layouts: data ?? [],
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
