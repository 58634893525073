import { useTranslation } from "react-i18next";
import { useSession } from "../hooks/SessionProvider";
import { useSelfUser } from "../hooks/useSelfUser";
import { User } from "../entities";
import axios from "axios";
import { AvailableLanguages } from "../i18n/config";
import NotificationSettingsRow from "../common/NotificationSettingsRow";
import { Button } from "../components/ui/button";
import Dropdown from "../components/Dropdown";
import {
  PageContent,
  PageFooter,
  PageHeader,
  PageTitle,
} from "../components/ui/page";
import { Badge } from "../components/ui/badge";
import { toast } from "sonner";

const Settings = () => {
  const { t: getTranslationByLabel, i18n } = useTranslation();
  const { signOut } = useSession();
  const { user, refresh } = useSelfUser();

  const handleConfigurationChange = (
    key: keyof User.UserConfiguration,
    value: boolean | "en" | "fr"
  ) => {
    if (user && user.userConfiguration) {
      const newConfiguration: User.UserConfiguration = {
        ...user.userConfiguration,
        [key]: value,
      };
      console.log(newConfiguration);
      if (
        !newConfiguration.lockerEmailNotificationsEnabled &&
        !newConfiguration.lockerAppNotificationsEnabled &&
        !newConfiguration.smsLockerNotificationsEnabled
      ) {
        toast.error("At least one locker notification must be enabled");
        return;
      }

      axios
        .put(`/v1/user/configurations/${user.id}`, newConfiguration)
        .then(() => {
          refresh().then(() => {
            if (value === "en") {
              i18n.changeLanguage(AvailableLanguages.EN);
            } else if (value === "fr") {
              i18n.changeLanguage(AvailableLanguages.FR);
            }
          });
        })
        .catch((error) => {
          console.log("Error updating user -> ", error);
          // setLoadingConfiguration(false);
        });
    }
  };
  return (
    <div className="flex flex-col items-stretch">
      <PageHeader>
        <PageTitle>{getTranslationByLabel("settings-menu")}</PageTitle>
      </PageHeader>
      {user && user.userConfiguration && (
        <PageContent className="space-y-4">
          <div className="space-y-2 border rounded p-3">
            <div>
              <div className="flex items-center space-x-4">
                <div>
                  {user.firstName} {user.lastName}
                </div>
                <Badge>{getTranslationByLabel(user.role)}</Badge>
              </div>
              <div className="text-muted-foreground text-sm">{user.email}</div>
            </div>

            <div>
              <Dropdown
                items={[
                  { label: "EN", value: "en" },
                  { label: "FR", value: "fr" },
                ]}
                value={user.userConfiguration.language}
                wrapperCss="w-fit"
                triggerClassList="h-8 "
                onChange={(v) =>
                  handleConfigurationChange("language", v as "fr" | "en")
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="border rounded p-3">
              <div className="text-base text-foreground font-semibold">
                {getTranslationByLabel("locker-settings")}
              </div>
              <NotificationSettingsRow
                disabled={!user.lockerFeatureEnabled}
                label={getTranslationByLabel("receive-email-notification")}
                isSwitchOn={
                  user.userConfiguration.lockerEmailNotificationsEnabled
                }
                onToggleSwitch={(value) => {
                  handleConfigurationChange(
                    "lockerEmailNotificationsEnabled",
                    value
                  );
                }}
              />
              <NotificationSettingsRow
                disabled={!user.lockerFeatureEnabled}
                label={getTranslationByLabel("receive-mobile-notification")}
                isSwitchOn={
                  user.userConfiguration.lockerAppNotificationsEnabled
                }
                onToggleSwitch={(value) => {
                  handleConfigurationChange(
                    "lockerAppNotificationsEnabled",
                    value
                  );
                }}
              />
              <NotificationSettingsRow
                disabled={!user.lockerFeatureEnabled}
                label={getTranslationByLabel("receive-sms-notification")}
                isSwitchOn={
                  user.userConfiguration.smsLockerNotificationsEnabled
                }
                onToggleSwitch={(value) => {
                  handleConfigurationChange(
                    "smsLockerNotificationsEnabled",
                    value
                  );
                }}
              />
              {user.role === "BUILDING_MANAGER" && (
                <NotificationSettingsRow
                  disabled={!user.lockerFeatureEnabled}
                  label={getTranslationByLabel(
                    "receive-late-package-notification"
                  )}
                  isSwitchOn={user.userConfiguration.notifyAdminLatePackage}
                  onToggleSwitch={(value) => {
                    handleConfigurationChange("notifyAdminLatePackage", value);
                  }}
                />
              )}
            </div>

            <div className="border rounded p-3">
              <div className="text-base text-foreground font-semibold">
                {getTranslationByLabel("system-notifications")}
              </div>
              <NotificationSettingsRow
                disabled={!user.notificationFeatureEnabled}
                label={getTranslationByLabel("receive-email-notification")}
                isSwitchOn={user.userConfiguration.emailNotificationsEnabled}
                onToggleSwitch={(value) => {
                  handleConfigurationChange("emailNotificationsEnabled", value);
                }}
              />
              <NotificationSettingsRow
                disabled={!user.notificationFeatureEnabled}
                label={getTranslationByLabel("receive-mobile-notification")}
                isSwitchOn={user.userConfiguration.appNotificationsEnabled}
                onToggleSwitch={(value) => {
                  handleConfigurationChange("appNotificationsEnabled", value);
                }}
              />
              <NotificationSettingsRow
                disabled={!user.notificationFeatureEnabled}
                label={getTranslationByLabel("receive-sms-notification")}
                isSwitchOn={user.userConfiguration.smsNotificationsEnabled}
                onToggleSwitch={(value) => {
                  handleConfigurationChange("smsNotificationsEnabled", value);
                }}
              />
            </div>
            <div className="text-sm text-muted-foreground">
              {getTranslationByLabel("contact-provider")}
            </div>
          </div>
          <div className="text-sm text-red-800">
            {getTranslationByLabel("notify_issue")}{" "}
            <a href="mailto:support@marvelock.com" className="underline">
              support@marvelock.com
            </a>
          </div>
        </PageContent>
      )}
      <PageFooter>
        <Button variant="destructive-outline" onClick={signOut}>
          {getTranslationByLabel("sign-out")}
        </Button>
      </PageFooter>
    </div>
  );
};

export default Settings;
