import { routes } from "../services/users";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { User } from "../entities";
import { sortByCreatedAt } from "../lib/utils";

interface Query {
  page: number;
  items: number;
  roles?: User.UserType[];
  sort?: string;
  sortDirection?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  organisationId?: string;
  locationId?: string;
}

export function useUsers(query: Query) {
  const { data, error, mutate } = useSWR(
    () => ({
      url: routes.list,
      queryParam: {
        ...query,
      },
    }),
    fetcher<any>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    users: data?.content ?? [],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
