import { Col } from "../../../styles/layout";
import Input from "../../../components/Input";
import { useEffect, useState } from "react";

import { update, deleteComponent } from "../../../services/components";
import HallnewsComponent from "../../../entities/HallnewsComponent";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import DeleteConfirmationDialog from "../../../common/DeleteConfirmationDialog";

interface OwnProps {
  id?: string;
  open: boolean;
  onClose: () => void;
  onDeleted: () => void;
  component: HallnewsComponent.Type;
}

const UpdateComponentDialog = ({
  component,
  open,
  onClose,
  onDeleted,
}: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();

  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const [title, setTitle] = useState<string | null>(null);

  const [error, setError] = useState<string | null>(null);

  const handleUpdate = async () => {
    if (!title) {
      setError("Remplissez chaque champ");
    } else {
      try {
        await update({
          id: component.id,
          label: title,
        });
        onClose();
      } catch (e: any) {
        setError(e.toString());
        console.error(e);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteComponent(component.id!);
      onDeleted();
      onClose();
    } catch (e: any) {
      setError(e.toString());
      console.error(e);
    }
  };

  const handleClose = () => {
    setTitle(null);
    // setSelectedLocations([]);
    setError(null);
    onClose();
  };

  useEffect(() => {
    if (component) {
      setTitle(component.label);
    }
  }, [component]);

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {getTranslationByLabel("hallnews-component-update")}
            </DialogTitle>
          </DialogHeader>
          <Col>
            <Col>
              <Input
                value={title}
                controlled
                label={getTranslationByLabel("hallnews-component-update-title")}
                onChange={(v: string) => {
                  setError(null);
                  setTitle(v);
                }}
                labelStyle={{
                  fontWeight: 700,
                  color: "textLow",
                  fontSize: "16px",
                }}
                containerStyle={{ mb: "4" }}
              />
            </Col>
          </Col>
          <DialogFooter>
            <Button
              variant="destructive-outline"
              onClick={() => setShowDeleteDialog(true)}
            >
              {getTranslationByLabel("delete")}
            </Button>

            <Button variant="default" onClick={handleUpdate}>
              {getTranslationByLabel("update")}
            </Button>
          </DialogFooter>
        </DialogContent>
        <DeleteConfirmationDialog
          open={showDeleteDialog}
          // disabled={isSubmitting}
          onConfirm={handleDelete}
          onClose={() => {
            setShowDeleteDialog(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default UpdateComponentDialog;
