import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { DialogDescription } from "@radix-ui/react-dialog";

interface OwnProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const DeleteConfirmationDialog = ({ open, onConfirm, onClose }: OwnProps) => {
  const { t: getTranslationByLabel } = useTranslation();
  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {getTranslationByLabel("delete-item-title")}
          </DialogTitle>
          <DialogDescription className="text-muted-foreground">
            {getTranslationByLabel("delete-item-content")}
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button
            // disabled={disabled}
            className="button"
            variant="outline"
            onClick={onClose}
          >
            {getTranslationByLabel("cancel")}
          </Button>
          <Button
            variant="destructive"
            color="error"
            autoFocus
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            {getTranslationByLabel("confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
