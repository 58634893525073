"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { fr } from "date-fns/locale";

import { cn } from "../../lib/utils";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Label } from "../Label";

interface Props {
  label?: string;
  disabled?: boolean;
  date: Date | undefined;
  onChange: (date: Date | undefined) => void;
  dob?: boolean;
  fromDate?: Date;
}
export function DatePicker({
  label,
  disabled,
  date,
  onChange,
  dob,
  fromDate,
}: Props) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex flex-col">
          {label && <Label label={label} />}
          <Button
            variant={"outline"}
            disabled={disabled}
            type="button"
            className={cn(
              "w-full justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date ? (
              format(new Date(date), "PPP", { locale: fr })
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={onChange}
          initialFocus
          captionLayout={"dropdown-buttons"}
          fromYear={new Date().getFullYear() - 100}
          toYear={new Date().getFullYear() - (dob ? 18 : 0)}
          fromDate={fromDate}
        />
      </PopoverContent>
    </Popover>
  );
}
