import moment from "moment";
import * as Yup from "yup";

export const NUMBER_REGEX = /^\d+$/;
export const PHONE_REGEX = /^\+\d+$/;

const getEmailValidationField = (label: string, requiredLabel: string) => {
  return Yup.string().email(label).required(requiredLabel);
};

export const getEmailValidationSchema = (
  invalidEmailLabel: string,
  emailRequiredLabel: string
) => {
  return Yup.object().shape({
    email: getEmailValidationField(invalidEmailLabel, emailRequiredLabel),
  });
};

export const getLoginValidationSchema = (
  invalidEmailLabel: string,
  emailRequiredLabel: string,
  passwordRequiredLabel: string
) => {
  return Yup.object().shape({
    email: getEmailValidationField(invalidEmailLabel, emailRequiredLabel),
    password: Yup.string().required(passwordRequiredLabel),
  });
};

export const getCreateSystemNotificationValidationSchema = (
  requiredLabel: string
) => {
  return Yup.object().shape({
    messageEn: Yup.string().required(requiredLabel),
    messageFr: Yup.string().required(requiredLabel),
    description: Yup.string().required(requiredLabel),
    locations: Yup.array().min(1, requiredLabel),
    effectiveDate: Yup.date().min(moment(), "Invalid"),
    termDate: Yup.date().min(Yup.ref("effectiveDate"), "Invalid"),
  });
};

export const getCreateLockerValidationSchema = (
  requiredLabel: string,
  maxLengthLabel: string
) => {
  return Yup.object().shape({
    lockerName: Yup.string().max(30, maxLengthLabel).required(requiredLabel),
    location: Yup.object().required(requiredLabel),
    bins: Yup.array().min(1, requiredLabel),
  });
};

export const getCreateUserValidationSchema = (
  requiredLabel: string,
  invalidEmailLabel: string,
  invalidPhoneLabel: string,
  maxLengthLabel: string,
  maxBadgeIdLengthLabel: string
) => {
  return Yup.object().shape({
    firstName: Yup.string().max(30, maxLengthLabel).required(requiredLabel),
    middleName: Yup.string().max(30, maxLengthLabel),
    lastName: Yup.string().max(30, maxLengthLabel).required(requiredLabel),
    badgeId: Yup.string().max(30, maxBadgeIdLengthLabel),
    type: Yup.string().required(requiredLabel),
    email: getEmailValidationField(invalidEmailLabel, requiredLabel),
    locationACLs: Yup.array().min(1),
    phone: Yup.string()
      .matches(PHONE_REGEX, invalidPhoneLabel)
      .max(15)
      .required(requiredLabel),
    userConfiguration: Yup.object().shape({
      language: Yup.string().required(requiredLabel),
    }),
  });
};

export const getCreateLocationValidationSchema = (
  requiredLabel: string,
  invalidPhoneLabel: string
) => {
  return Yup.object().shape({
    city: Yup.string().required(requiredLabel),
    country: Yup.string().required(requiredLabel),
    address: Yup.string().required(requiredLabel),
    building: Yup.string().optional(),
    floor: Yup.number().optional(),
    phoneNumber: Yup.string().max(15).required(requiredLabel),
    name: Yup.string().required(requiredLabel),
    image: Yup.string().optional(),
    companyName: Yup.string(),
    latitude: Yup.number().optional(),
    longitude: Yup.number().optional(),
  });
};
