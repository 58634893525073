import { cn } from "../lib/utils";
import { ReactNode, useMemo } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./Label";
import { Button } from "./ui/button";
import { LucideX } from "lucide-react";

export type DropDownItem = {
  label: string | ReactNode;
  value: string;
};
interface Props {
  label?: string;
  value?: string;
  onChange: (value: string | undefined) => void;
  items: DropDownItem[];
  initialValue?: string;
  placeholder?: string;
  dropdownCss?: string;
  triggerClassList?: string;
  wrapperCss?: string;
  required?: boolean;
  disabled?: boolean;
  disableClearing?: boolean;
}

const Dropdown: React.FC<Props> = ({
  label,
  items,
  initialValue,
  value,
  onChange,
  placeholder,
  wrapperCss,
  triggerClassList,
  required,
  disabled,
  disableClearing,
}) => {
  const formattedItems = useMemo(
    () => items.map(({ label, value: v }) => ({ label, value: v.toString() })),
    [items]
  );

  const clear = () => {
    onChange(undefined);
  };

  return (
    <div className={cn(wrapperCss)}>
      {label && <Label label={`${label}${required ? "*" : ""}`} />}

      <Select
        onValueChange={onChange}
        defaultValue={initialValue}
        disabled={disabled}
        value={value ?? ""}
      >
        <div className={"flex items-center space-x-0"}>
          <SelectTrigger
            className={cn("border border-input rounded", triggerClassList)}
          >
            <SelectValue
              placeholder={placeholder ?? "Sélectionner"}
              defaultValue={initialValue}
            />
          </SelectTrigger>
          {!!value && !disableClearing && (
            <Button variant="ghost" size="icon" className="" onClick={clear}>
              <LucideX size={12} />
            </Button>
          )}
        </div>
        <SelectContent>
          {formattedItems.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default Dropdown;
