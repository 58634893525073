import { Components } from "../__containers/Components";

const BuildingManagerHome = () => {
  return (
    <div className="space-y-6">
      <Components />
    </div>
  );
};

export default BuildingManagerHome;
