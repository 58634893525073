import { apiFetch } from "../lib/apiFetch";

const API = process.env.REACT_APP_API_URL;

export const routes = {
  reports: `${API}/v2/service-orders/report`,
  export: `${API}/v2/service-orders/export`,
};

export const exportServiceOrders = (query: any) =>
  apiFetch({
    method: "GET",
    url: routes.export,
    authRequired: true,
    queryParam: query,
  });
