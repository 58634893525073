import { routes } from "../services/layouts";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";

export function useLocationLayout(locationId: string) {
  const { data, error, mutate } = useSWR(
    { url: routes.findByLocationId, queryParam: { locationId } },
    fetcher<any>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    layout: data,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
