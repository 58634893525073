import Input from "../../../components/Input";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

import { create as createComponent } from "../../../services/components";
import Organisation from "../../../entities/Organisation";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import { useOrganisations } from "../../../hooks/useOrganisations";
interface OwnProps {
  id?: string;
  open: boolean;
  onClose: () => void;
}

const NewComponentDialog = ({ id, open, onClose }: OwnProps) => {
  const { organisations } = useOrganisations();

  const { t: getTranslationByLabel } = useTranslation();

  const [title, setTitle] = useState<string | undefined>();
  const [type, setType] = useState<string | undefined>();
  const [organisation, setOrganisation] = useState<
    Organisation.Type | undefined
  >();

  const handleCreate = async () => {
    console.log(title, type);
    if (!title || !type) {
    } else {
      try {
        const response = await createComponent({
          label: title,
          type,
          organization: organisation,
        });
        console.log(response);
        onClose();
      } catch (e: any) {
        console.error(e);
      }
    }
  };

  const handleClose = () => {
    setTitle(undefined);
    setType(undefined);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {getTranslationByLabel("hallnews-component-new-header")}
            </DialogTitle>
            <DialogDescription>
              {getTranslationByLabel("hallnews-component-new-subheader")}
            </DialogDescription>
          </DialogHeader>
          <div></div>
          <Dropdown
            label="Organisation"
            items={organisations.map((org) => ({
              label: org.name,
              value: org.id?.toString() ?? "",
            }))}
            placeholder="Organisation"
            value={organisation?.id}
            onChange={(v) => {
              setOrganisation(
                organisations.find((o) => o.id?.toString() === v)!
              );
            }}
          />

          <Input
            label={getTranslationByLabel("hallnews-component-new-title")}
            onChange={(v: string) => {
              setTitle(v);
            }}
          />
          <Dropdown
            label="Type"
            items={[
              { label: "Notifications", value: "notifications" },
              { label: "Image", value: "image" },
              { label: "Videos", value: "video" },
            ]}
            placeholder="Type de contenu"
            value={type ?? ""}
            onChange={(v) => {
              setType(v);
            }}
          />

          <DialogFooter>
            <Button variant="default" onClick={handleCreate}>
              {getTranslationByLabel("create")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewComponentDialog;
