import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

import { create as createLayout } from "../../../services/layouts";

import { useTranslation } from "react-i18next";
import Dropdown from "../../../components/Dropdown";
import { useLocations } from "../../../hooks/useLocations";
import { Location } from "../../../entities";
interface OwnProps {
  id?: string;
  open: boolean;
  onClose: () => void;
}

interface FormData {
  location: Location.Type;
}

const NewLayoutDialog = ({ id, open, onClose }: OwnProps) => {
  const { locations } = useLocations();

  const { t: getTranslationByLabel } = useTranslation();

  const [location, setLocation] = useState<Location.Type | undefined>();

  const handleNewLayout = async () => {
    try {
      await createLayout({
        location,
        gridRows: 3,
        gridCols: 3,
        gutter: 24,
        margin: 24,
        headerHeight: 88,
        footerHeight: 88,
        imageUrl:
          "https://media.istockphoto.com/id/1450806481/fr/photo/%C3%A9l%C3%A9ments-modernes-dans-larchitecture-contemporaine.jpg?s=612x612&w=0&k=20&c=qD42D5AFtIRWTrAls4iApSmFNPdvgHCzDtLxtPzI0HA=",
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleClose = () => {
    setLocation(undefined);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {getTranslationByLabel("hallnews-layout-new-title")}
            </DialogTitle>
            <DialogDescription>
              {getTranslationByLabel("hallnews-layout-new-description")}
            </DialogDescription>
          </DialogHeader>
          <div></div>
          <Dropdown
            label="Location"
            items={locations.map((loc) => ({
              label: loc.name,
              value: loc.id?.toString() ?? "",
            }))}
            placeholder="Location"
            value={location?.id}
            onChange={(v) => {
              setLocation(locations?.find((o) => o.id?.toString() === v)!);
            }}
          />

          <DialogFooter>
            <Button variant="default" onClick={handleNewLayout}>
              {getTranslationByLabel("create")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewLayoutDialog;
