import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SortDirection } from "../filters";
import { FilterKeys } from "../components/ItemsFilter";
import { User } from "../entities";

interface LocationFilters {
  sortDirection: SortDirection;
  organization: string;
  location: string;
  role?: User.UserType;
}
export function useLocationFilters(): LocationFilters {
  const routerLocation = useLocation();
  const [organization, setOrganization] = useState("");
  const [location, setLocation] = useState("");
  const [sortDirection, setSortDirection] = useState<SortDirection>("");
  const [role, setRole] = useState<User.UserType>();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(routerLocation.search);

    setOrganization(urlSearchParams.get(FilterKeys.Organisation) ?? "");
    setLocation(urlSearchParams.get(FilterKeys.Location) ?? "");
    setSortDirection(
      (urlSearchParams.get(FilterKeys.SortDirection) as SortDirection) ?? ""
    );
    setRole((urlSearchParams.get(FilterKeys.Role) ?? "") as User.UserType);
  }, [routerLocation.search]);

  return {
    sortDirection,
    organization,
    location,
    role,
  };
}
