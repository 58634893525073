import Bin from "./Bin";
import Location from "./Location";
import Locker from "./Locker";
import Organisation from "./Organisation";
import User from "./User";

export namespace ServiceOrderReport {
  export type Type = {
    id: string;
    parcelTrackingNumber: string;
    pickupBy: string;
    state: string;
    type: string;
    effectiveDate: Date;
    user: User.Type;
    bins: Bin.Type[];
    locker: Locker.Type;
    location: Location.Type;
    accessCode: string;
    organisation: Organisation.Type;
  };
}

export default ServiceOrderReport;
