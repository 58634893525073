import React, { useState } from "react";
import {
  position,
  flexbox,
  layout,
  space,
  color,
  border,
  typography,
  shadow,
  grid,
} from "styled-system";

import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { Hoverable, StyledProps } from "../../styles/layout";
import { cn } from "../../lib/utils";

const Container = styled(animated.div)<StyledProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${typography}
  ${shadow}
  ${grid}

  &:first-of-type {
    margin-left: 0px;
  }
  &:last-of-type {
    margin-right: 0px;
  }
`;

export const ClickableCard: React.FC<any> = React.forwardRef(
  (
    { children, disabled = false, onClick, scaleDown = 0.995, ...props },
    ref: React.ForwardedRef<HTMLDivElement>
  ): JSX.Element => {
    const [hovered, setHovered] = useState(false);

    const styles = useSpring({
      transform: hovered && !disabled ? `scale(${scaleDown})` : "scale(1)",
      config: { tension: 300, friction: 20 },
    });

    const mountStyles = useSpring({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    });

    return (
      <div
        className={cn("cursor-pointer", {
          "pointer-events-none": disabled,
        })}
        ref={ref}
        {...props}
      >
        <Container
          ref={ref}
          style={{ ...mountStyles, ...styles }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={onClick}
          height="100%"
          width="100%"
        >
          {children}
        </Container>
      </div>
    );
  }
);
