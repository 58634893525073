import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Col, Image } from "../styles/layout";
import EmailForm from "../components/AuthForm/EmailForm";
import OTPForm from "../components/AuthForm/OTPForm";
import { useSession } from "../hooks/SessionProvider";
import { APPLICATION_PATH } from "../routes";
import { useRouter } from "../hooks/useRouter";

const Login = () => {
  const router = useRouter();

  const { authenticated } = useSession();
  const [step, setStep] = useState<"email" | "otp">("email");
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    if (authenticated) {
      router.push(APPLICATION_PATH);
    }
  }, [authenticated, router]);

  return (
    <Col
      width="100vw"
      height="100vh"
      justifyContent={"center"}
      alignItems="center"
      style={{
        backgroundImage: `url(${require("../assets/background.jpg")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Col
        pt="44px"
        px="24px"
        justifyContent="flex-start"
        alignItems={"stretch"}
        bg="white"
        boxShadow="0px 3px 6px #00000029"
        width="340px"
        borderRadius="1"
        overflow="hidden"
      >
        <Col alignItems={"center"} mb="24px">
          <Image
            src={`/logo192.png`}
            height="56px"
            width="100%"
            style={{ objectFit: "contain" }}
            marginBottom="16px"
          />
          <Image
            src={`/logo-marveLock.png`}
            height="56px"
            width="100%"
            style={{ objectFit: "contain" }}
          />
        </Col>
        <AnimatePresence>
          {step === "email" && (
            <EmailForm
              onComplete={(email: string) => {
                setEmail(email);
                setStep("otp");
              }}
            />
          )}
          {step === "otp" && email && (
            <OTPForm email={email} onComplete={() => setStep("email")} />
          )}
        </AnimatePresence>
      </Col>
    </Col>
  );
};

export default Login;
