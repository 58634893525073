import { routes } from "../services/locations";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { Location } from "../entities";

export function useLocations(
  page = 0,
  limit = 1000,
  filter?:
    | {
        organization?: string | undefined;
        location?: string | undefined;
        sortDirection?: string | undefined;
      }
    | undefined,
  disabled: boolean = false
) {
  const { data, error, mutate } = useSWR(
    () =>
      disabled
        ? undefined
        : {
            url: routes.list,
            queryParam: {
              page,
              items: limit,
              ...filter,
            },
          },
    fetcher<any>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    locations: (data?.content ?? []) as Location.Type[],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh: mutate,
  };
}
