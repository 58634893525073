import { routes } from "../services/users";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import { User } from "../entities";

export function useSelfUser(authenticated = true) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (authenticated ? { url: routes.self } : undefined),
    fetcher<User.Self>,
    {
      suspense: false,
      shouldRetryOnError: false,
      errorRetryCount: 2,
    }
  );

  return {
    user: data,
    refresh: mutate,
    isLoading,
    isError: error,
  };
}
