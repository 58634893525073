import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Pagination } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import UserDialog from "../components/UserDialog";
import { User } from "../entities";
import { usePagination } from "../hooks/usePagination";
import { useUsers } from "../hooks/useUsers";
import { useSession } from "../hooks/SessionProvider";
import CopyToClipboardButton from "../common/CopyToClipboardButton";
import { formatDate } from "date-fns";
import { Button } from "../components/ui/button";
import { ClipLoader } from "react-spinners";
import { LucideCheckCircle, LucidePlus, LucideXCircle } from "lucide-react";
import ItemsFilter from "../components/ItemsFilter";
import { useLocationFilters } from "../hooks/useLocationFilters";
import { PageContent, PageHeader, PageTitle } from "../components/ui/page";
import { cn } from "../lib/utils";
import Input from "../components/Input";

const PAGE_SIZE = 8;

const Users = () => {
  const routerLocation = useLocation();
  const { t: getTranslationByLabel } = useTranslation();
  const [page, setPage] = usePagination();
  const [dialogOpened, setDialogOpened] = useState(false);
  const [name, setName] = useState<string | undefined>();

  const [user, setUser] = useState<User.Type | undefined>();
  const { sortDirection, organization, location, role } = useLocationFilters();

  const { users, pages, isLoading, refresh } = useUsers({
    page: (page ?? 0) - 1,
    items: PAGE_SIZE,
    roles: role ? [role] : [],
    sort: "created_at",
    sortDirection,
    lastName: name,
    organisationId: organization,
    locationId: location,
  });

  const [timeoutId, setTimeoutId] = useState<any | undefined>(null);
  const debounce = (func: (d: any) => void, data: any, delay: number) => {
    if (timeoutId) {
      clearTimeout(timeoutId as string);
    }
    setTimeoutId(setTimeout(() => func(data), delay));
  };

  return (
    <div>
      <PageHeader>
        <div className="flex justify-between">
          <PageTitle>{getTranslationByLabel("users-menu")}</PageTitle>
          <Button
            onClick={() => setDialogOpened(true)}
            variant="default"
            className="space-x-2"
          >
            <LucidePlus size={16} />
            <span>{getTranslationByLabel("create-new")}</span>
          </Button>
        </div>
        <div className="space-y-2 ">
          <div className="flex items-center space-x-4 justify-between ">
            <div className="flex items-center space-x-2">
              <ItemsFilter showLocations showRole />
              <div className="flex items-center">
                <Input
                  placeholder={`${getTranslationByLabel("enter-last-name")}...`}
                  onChange={(n: string) => debounce(setName, n, 300)}
                />
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <Pagination
                onChange={(_, page) => {
                  setPage(page, routerLocation.search);
                }}
                page={page ?? 1}
                count={pages}
                variant="outlined"
                color="primary"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </PageHeader>
      <PageContent>
        <table className="min-w-full text-left text-sm font-light">
          <thead className="border-b border-border font-medium">
            <tr className="pb-4 text-muted-foreground">
              <th className="w-8"></th>
              <th className="py-4 w-32">Ajouté le</th>
              <th className="py-4 w-48">Role</th>
              <th className="py-4 w-80">Nom</th>
              <th className="py-4">Organisation(s)</th>
              <th className="py-4">Location(s)</th>
              <th className="py-4"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: User.Type) => (
              <tr
                key={user.id}
                className="cursor-pointer rounded-sm border-b border-border  transition-all duration-200 ease-in-out  last:border-b-0 "
              >
                <td
                  className={cn({
                    "text-green-500": user.isEnabled,
                    "text-red-500": !user.isEnabled,
                  })}
                >
                  {user.isEnabled ? (
                    <LucideCheckCircle size={16} />
                  ) : (
                    <LucideXCircle size={16} />
                  )}
                </td>
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {formatDate(new Date(user.createdAt), "dd/MM/yyyy")}
                </td>
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  {getTranslationByLabel(user.type)}
                </td>
                <td className="text-foreground  whitespace-nowrap px-1 py-5 text-sm font-light ">
                  <div className="flex flex-col ">
                    <div> {`${user.firstName} ${user.lastName}`}</div>
                    <div className="font-light text-xs text-muted-foreground">
                      {user.email}
                    </div>
                  </div>
                </td>
                <td className="text-foreground  whitespace-nowrap text-ellipsis overflow-hidden max-w-44 px-1 py-5 text-sm font-light ">
                  {user.organizations
                    .map((l) => l.organization.name)
                    .join(", ")}
                </td>
                <td className="text-foreground  whitespace-nowrap text-ellipsis overflow-hidden max-w-44 px-1 py-5 text-sm font-light ">
                  {user.locations.map((l) => l.name).join(", ")}
                </td>

                <td className="">
                  <div className="flex items-center justify-end space-x-4">
                    <CopyToClipboardButton valueToCopy={user.id} />
                    <Button
                      size="sm"
                      onClick={() => {
                        setDialogOpened(true);
                        setUser(user);
                      }}
                    >
                      {getTranslationByLabel("edit")}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            {users.length === 0 && !isLoading && (
              <tr>
                <td colSpan={8} className="py-4 text-center">
                  Aucun utilisateur trouvé
                </td>
              </tr>
            )}
            {users.length === 0 && isLoading && (
              <tr>
                <td colSpan={8} className="py-6 text-center">
                  <ClipLoader size="medium" color="secondary" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </PageContent>

      {(!!user || dialogOpened) && (
        <UserDialog
          open={!!user || dialogOpened}
          user={user}
          onClose={(shouldRefetch?: boolean) => {
            setUser(undefined);
            setDialogOpened(false);
            if (shouldRefetch) {
              refresh();
            }
          }}
        />
      )}
    </div>
  );
};

export default Users;
