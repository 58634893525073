import { cn } from "../lib/utils";
import { forwardRef } from "react";
import { Button } from "./ui/button";

interface Props {
  className?: string;
  value?: number;
  onChange: (value: number) => void;
  max: number;
  addPlus?: boolean;
}

export const CountPicker = forwardRef<HTMLInputElement, Props>(
  ({ className, value, onChange, max, addPlus, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn("flex items-center space-x-4", className)}
        {...props}
      >
        {Array.from({ length: max }, (_, i) => (
          <Button
            key={i + 1}
            variant="outline"
            type="button"
            onClick={() => onChange(i + 1)}
            className={cn("aspect-square rounded-full", {
              "bg-secondary hover:bg-secondary/90": value === i + 1,
            })}
            aria-label={`Choisir ${i + 1}`}
          >
            {i + 1}
          </Button>
        ))}
      </div>
    );
  }
);

CountPicker.displayName = "CountPicker";
