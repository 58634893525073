import React, { useEffect, useState } from "react";
// import { styled as stitches } from '@stitches/react';
import { Col } from "../../styles/layout";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import { border, color, layout, space, typography } from "styled-system";
import { Text } from "../../styles/typography";
import { Input } from "../ui/input";
import { Label } from "../Label";

const getColor = ({ opposite }: { opposite: boolean }, c: string) =>
  themeGet(opposite ? `colors.opposite.${c}` : `colors.${c}`);

export const StyledInput = styled.input`
  ${layout}
  ${space}
  ${color}
  ${border}
  ${typography}

  outline: none;
  border: none;
  background-color: ${(props: any) => getColor(props, "bgBase")} !important;
  border: 1px solid ${(props: any) => getColor(props, "mvl.primaryVariant")};
  padding-left: ${themeGet("space.3")}px;

  color: ${(props: any) => getColor(props, "text.main.high")} !important;

  &:hover {
    border: 1px solid ${(props: any) => getColor(props, "mvl.secondary")};
  }

  &:focus {
    border: 1px solid ${(props: any) => getColor(props, "mvl.secondary")};
  }

  &:autofill {
    background-color: ${(props: any) => getColor(props, "element")};
  }
`;

// Exports

export default React.forwardRef(
  (
    {
      name,
      label,
      value,
      labelStyle,
      containerStyle,
      inputStyle,
      opposite,
      error,
      formatter,
      onChange,
      type,
      controlled,
      ...props
    }: any,
    ref
  ) => {
    const [innerValue, setInnerValue] = useState<string>(value ?? "");

    useEffect(() => {
      if (controlled) {
        setInnerValue(value);
      }
    }, [controlled, value]);

    return (
      <Col alignItems="stretch" {...containerStyle}>
        {label && <Label label={label} error={error} />}
        <Input
          value={innerValue}
          type={type}
          onChange={(e) => {
            let val = e.target.value;
            if (formatter) {
              val = formatter(e);
              e.target.value = val;
            }
            setInnerValue(val);
            return onChange(e.target.value);
          }}
          {...props}
        />

        {error && <p className="text-xs text-destructive">{error}</p>}
      </Col>
    );
  }
);
