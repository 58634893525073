import styled from "styled-components";

import {
  flexbox,
  layout,
  space,
  color,
  border,
  typography,
  PositionProps,
  LayoutProps,
  ColorProps,
  FlexboxProps,
  BorderProps,
  TypographyProps,
  ShadowProps,
  GridProps,
  SpaceProps,
} from "styled-system";

interface Props
  extends ColorProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    PositionProps,
    BorderProps,
    TypographyProps,
    ShadowProps,
    GridProps {
  children: React.ReactNode;
}

export const Text = styled.div<Props>`
  line-height: 1.3;

  font-family: "Avenir", sans-serif;
  // letter-spacing: 0.05em;
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
`;

export const TextNoWrap = styled.div`
  line-height: 1.3;
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${border}
  ${flexbox}

  white-space: nowrap;
  max-width: 60ch;
  user-select: none;
`;

export const TextEllipsis = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SelectableText = styled.div`
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${flexbox}

  white-space: pre-line;
  max-width: 60ch;
`;

export const Underline = styled.div`
  ${color}
  ${typography}
  ${space}
  ${layout}
  text-decoration: underline;
`;

export const Span = styled.span`
  line-height: 1.3;

  white-space: pre-line;
  max-width: 60ch;
  user-select: none;
  font-family: "Prompt", sans-serif;
  // letter-spacing: 0.05em;
  ${color}
  ${typography}
  ${space}
  ${layout}
  ${border}
  ${flexbox}
`;

export const A = styled.a`
  ${color}
  ${typography}
  ${space}
  ${layout}

  text-underline-offset: 20%;
  &:hover {
    cursor: pointer;
  }
`;
