import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const sortByCreatedAt = (collection: any[], sort = "ASC") => {
  return collection.sort(
    (a: any, b: any) =>
      (sort === "ASC" ? 1 : -1) *
      (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
  );
};

export const sortBy = (
  collection: any[],
  field = "createdAt",
  sort = "ASC"
) => {
  return collection.sort(
    (a: any, b: any) =>
      (sort === "ASC" ? 1 : -1) *
      (new Date(a[field]).getTime() - new Date(b[field]).getTime())
  );
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
