import { routes } from "../services/organisations";
import useSWR from "swr";
import { fetcher } from "../lib/apiFetch";
import Organisation from "../entities/Organisation";

export function useOrganisations(page = 0, limit = 1000) {
  const revalidationEnabled = page === 0;
  const {
    data,
    error,
    mutate: refresh,
  } = useSWR(
    { url: routes.list, queryParam: { page, items: limit } },
    fetcher<any>,
    {
      revalidateIfStale: revalidationEnabled,
      revalidateOnFocus: revalidationEnabled,
      revalidateOnReconnect: revalidationEnabled,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  return {
    organisations: (data?.content ?? []) as Organisation.Type[],
    page: data?.number,
    hasMore: data?.last,
    total: data?.totalElements,
    pages: data?.totalPages,
    isLoading: !error && !data,
    isError: error,
    refresh,
  };
}
