import React, { useEffect, useState } from "react";
import { Text } from "../../styles/typography";
import ScaleLoader from "react-spinners/ScaleLoader";
import { hslToHex } from "../../lib/colors";
import { getCurrentTheme } from "../../styles/theme";
import styled from "styled-components";
import {
  position,
  flexbox,
  layout,
  space,
  color,
  border,
  typography,
  shadow,
  variant as variantStyled,
} from "styled-system";
import { ClickableCard } from "../ClickableCard";

export const StyledButton = styled.button`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}
`;

const ButtonVariants = styled(StyledButton)(
  {
    appearance: "none",
    fontFamily: "inherit",
    outline: "none",
    border: "none",
    fontSize: "18px",
  },
  variantStyled({
    variants: {
      primary: {
        color: "text.opposite.high",
        bg: "accent",
        boxShadow: "0 0 1px 0.5px borderSubtle",
        "&:hover": {
          bg: "accentHover",
        },
        "&:active": {
          bg: "borderHover",
        },
      },
      primaryOpposite: {
        color: "text.opposite.high",
        bg: "opposite.accent",
        boxShadow: "0 0 1px 0.5px opposite.borderSubtle",
        "&:hover": {
          bg: "opposite.accentHover",
        },
        "&:active": {
          bg: "opposite.borderHover",
        },
      },
      primaryOutline: {
        color: "text.main.high",
        bg: "transparent",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "border",
        "&:hover": {
          bg: "elementHover",
          borderColor: "borderHover",
        },
        "&:active": {
          bg: "element",
          borderColor: "border",
        },
      },
      primaryOutlineDark: {
        color: "text.opposite.high",
        bg: "transparent",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "opposite.border",
        "&:hover": {
          bg: "opposite.element",
          borderColor: "opposite.borderSubtle",
        },
        "&:active": {
          bg: "opposite.elementFocus",
          borderColor: "opposite.borderHover",
        },
      },
      danger: {
        color: "danger.text.main.high",
        bg: "danger.accent",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "danger.border",

        "&:hover": {
          bg: "danger.accentHover",
          borderColor: "danger.borderHover",
        },
      },
      warning: {
        color: "warning.text.main.high",
        bg: "warning.accent",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "warning.border",

        "&:hover": {
          bg: "warning.accentHover",
          borderColor: "warning.borderHover",
        },
      },
      dangerOutline: {
        color: "danger.textLow",
        bg: "danger.element",
        borderWidth: "0.5px",
        borderStyle: "solid",
        borderColor: "danger.border",

        "&:hover": {
          bg: "danger.elementHover",
          borderColor: "danger.borderHover",
        },
      },
    },
  })
);

const Button = ({
  variant,
  label,
  labelStyle,
  icon,
  disabled,
  containerStyle,
  onClick,
  loading,
  ...props
}: any) => {
  const [loadingState, setLoadingState] = useState(!!loading);

  useEffect(() => {
    setLoadingState(!!loading);
  }, [loading]);

  const handleClick = async (...args: any) => {
    if (!disabled) {
      setLoadingState(true);
      try {
        await onClick(...args);
        setLoadingState(false);
      } catch (e) {
        setLoadingState(false);
      }
    }
  };

  const getLoaderColor = () => {
    if (variant === "primary") {
      return hslToHex(getCurrentTheme().colors.text.main.high);
    }
    if (variant === "primaryOpposite") {
      return hslToHex(getCurrentTheme().colors.text.opposite.high);
    }
    if (variant === "danger") {
      return hslToHex(getCurrentTheme().colors.danger.textLow);
    }
    return hslToHex(getCurrentTheme().colors.text.opposite.low);
  };

  return (
    <ClickableCard>
      <ButtonVariants
        variant={variant}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="44px"
        minWidth="156px"
        fontWeight="3"
        px="5"
        borderRadius="1"
        {...containerStyle}
        {...props}
        onClick={handleClick}
      >
        <ScaleLoader
          height={18}
          loading={loadingState}
          color={getLoaderColor()}
        />
        {!loadingState && <Text {...labelStyle}>{label}</Text>}
      </ButtonVariants>
    </ClickableCard>
  );
};

export default Button;
